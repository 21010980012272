import axios from "axios";
import { useEffect, useState } from "react";
import SideBar from "./SideBar";
import { ReactComponent as Search } from "../Images/svg/search.svg";
import { Link } from "react-router-dom";
import moment from "moment";

function UserManagement() {
  const [data, setData] = useState([]);
  const [filteredDataWithMasterclass, setFilteredDataWithMasterclass] = useState([]);
  const [filteredDataWithoutMasterclass, setFilteredDataWithoutMasterclass] = useState([]);

  const [page, setPage] = useState(1);

  useEffect(() => {
    const fetchData = async () => {
      const dataSend = {
        model: "participants",
      };
      try {
        const res = await axios.post("https://thebabyconvention.com/wbe/api/get_all.php", dataSend, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        const participants = res.data || [];
        console.log("Fetched participants: ", participants, data);

        // Filter participants that have chosen a masterclass
        const withMasterclass = participants.filter((item) => item.master_class && item.selected_master_class);

        // Filter participants without a masterclass
        const withoutMasterclass = participants.filter((item) => !item.master_class || !item.selected_master_class);
        console.log(withoutMasterclass);
        setData(participants);
        setFilteredDataWithMasterclass(withMasterclass);
        setFilteredDataWithoutMasterclass(withoutMasterclass);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [page]);

  return (
    <>
      <div className="bg-[#f5f5f6] h-fit w-full flex justify-between items-start">
        <SideBar />
        <div className=" 100:pt-[100px] xl:pt-[24px]  w-full h-fit  py-8 flex flex-col 100:px-[40px] xl:pl-[38vh] justify-start items-start gap-8">
          <div className=" w-full flex justify-between items-start lg:flex-row 100:gap-[16px] lg:gap-0 100:flex-col">
            <div className="w-full flex-col justify-start items-start gap-1 inline-flex">
              <h2 className="self-stretch text-[#3c3b3e] text-[32px] font-semibold font-openSans">Participants Management</h2>
              <p className="lg:w-[705px] 100:w-full text-[#6b6a70] text-base font-normal font-openSans">This section lets admins manage Participant accounts, search Participants, view profiles, and update information.</p>
            </div>
            <div className=" w-full flex justify-end">
              <Link to="/admin/reciept" className="w-fit mt-[24px]">
                <div className="w-full  px-[20px] py-2 bg-[#fe8734] whitespace-nowrap rounded-[20px] justify-center text-[#f5f5f6] text-[16px] font-semibold font-openSans items-center inline-flex">Check User</div>
              </Link>
            </div>
          </div>

          {/* TABLE FOR PARTICIPANTS WITH MASTERCLASS */}
          <div className=" w-full gap-[8px] flex-col justify-start items-start flex">
            <h5 className="self-stretch text-[#6b6a70] text-xs font-semibold font-openSans">Participants with Masterclass</h5>
            <div className="h-fit w-full whitespace-nowrap  100:overflow-x-scroll lg:overflow-y-hidden lg:overflow-x-hidden bg-white rounded-lg shadow border border-[#cfcfd2]  flex-col justify-center items-start gap-[24px] inline-flex">
              {" "}
              <div className=" h-[82px] flex justify-start items-center w-full p-[20px]">
                <div className="self-stretch 100:flex h-[42px] md:hidden gap-[12px]  w-full   px-3.5 py-2.5 bg-white rounded-[20px] shadow border border-[#aeaeb2] justify-start items-center  inline-flex text-[#6c757d] text-base font-normal leading-normal">
                  <Search /> <input className="rounded-lg pr-3.5 bg-transparent border-none outline-none w-full h-full" placeholder="Search" />
                </div>

                <div className="self-stretch gap-[12px] h-[42px] 100:hidden md:flex w-[400px]   px-3.5 py-2.5 bg-white rounded-[20px] shadow border border-[#aeaeb2] justify-start items-center  inline-flex text-[#6c757d] text-base font-normal leading-normal">
                  <Search /> <input className="rounded-lg pr-3.5 bg-transparent border-none outline-none w-full h-full" placeholder="Search" />
                </div>
              </div>
              <div className="h-fit w-full border-t border-t-[#CDD1D4] flex justify-between items-center ">
                {/* ROW 1*/}
                <div className="  w-[120px] flex flex-col justify-start items-start">
                  <div className="h-11 w-full px-6 py-3 bg-white justify-start items-center gap-3 inline-flex text-[#6b6a70] text-xs font-semibold font-openSans">ID</div>
                  {filteredDataWithMasterclass.map((item, index) => (
                    <div className={`h-[72px] w-full px-6 py-4 ${index % 2 === 0 ? "bg-[#f5f6f6]" : "bg-white"}  justify-start items-center gap-3 inline-flex text-[#4e4d51] text-sm font-normal font-openSans `}>{item.id}</div>
                  ))}
                </div>
                {/* ROW 2*/}
                <div className=" w-full flex flex-col justify-start items-start">
                  <div className="h-11 w-full px-6 py-3 bg-white justify-start items-center gap-3 inline-flex text-[#6b6a70] text-xs font-semibold font-openSans">Name</div>
                  {filteredDataWithMasterclass.map((item, index) => (
                    <div className={`h-[72px] w-full px-6 py-4 ${index % 2 === 0 ? "bg-[#f5f6f6]" : "bg-white"}  justify-start items-center gap-3 inline-flex text-[#4e4d51] text-sm font-normal font-openSans `}>
                      {item.first_name} {item.last_name}
                    </div>
                  ))}{" "}
                </div>
                {/* ROW 3*/}
                <div className=" w-full flex flex-col justify-start items-start">
                  <div className="h-11 w-full px-6 py-3 bg-white justify-start items-center gap-3 inline-flex text-[#6b6a70] text-xs font-semibold font-openSans">Email</div>
                  {filteredDataWithMasterclass.map((item, index) => (
                    <div className={`h-[72px] w-full px-6 py-4 ${index % 2 === 0 ? "bg-[#f5f6f6]" : "bg-white"}  justify-start items-center gap-3 inline-flex text-[#4e4d51] text-sm font-normal font-openSans `}>{item.email}</div>
                  ))}{" "}
                </div>

                {/* ROW 4*/}
                <div className=" w-full flex flex-col justify-start items-start">
                  <div className="h-11 w-full px-6 py-3 bg-white justify-start items-center gap-3 inline-flex text-[#6b6a70] text-xs font-semibold font-openSans">Phone Number</div>
                  {filteredDataWithMasterclass.map((item, index) => (
                    <div className={`h-[72px] w-full px-6 py-4 ${index % 2 === 0 ? "bg-[#f5f6f6]" : "bg-white"}  justify-start items-center gap-3 inline-flex text-[#4e4d51] text-sm font-normal font-openSans `}>{item.phone_number}</div>
                  ))}
                </div>
                {/* ROW 6*/}
                <div className=" w-full flex flex-col justify-start items-start">
                  <div className="h-11 w-full px-6 py-3 bg-white justify-start items-center gap-3 inline-flex text-[#6b6a70] text-xs font-semibold font-openSans">Master Class</div>
                  {filteredDataWithMasterclass.map((item, index) => (
                    <div className={`h-[72px] w-full px-6 py-4 ${index % 2 === 0 ? "bg-[#f5f6f6]" : "bg-white"}  justify-start items-center gap-3 inline-flex text-[#4e4d51] text-sm font-normal font-openSans `}>{item.master_class}</div>
                  ))}
                </div>
                {/* ROW 7*/}
                <div className=" w-full flex flex-col justify-start items-start">
                  <div className="h-11 w-full px-6 py-3 bg-white justify-start items-center gap-3 inline-flex text-[#6b6a70] text-xs font-semibold font-openSans">Master Class Type</div>
                  {filteredDataWithMasterclass.map((item, index) => (
                    <div className={`h-[72px] w-[220px] text-pretty   px-6 py-4 ${index % 2 === 0 ? "bg-[#f5f6f6]" : "bg-white"}  justify-start items-center  inline-flex text-[#4e4d51] text-sm font-normal font-openSans `}>
                      {item.selected_master_class}
                    </div>
                  ))}
                </div>

                {/* ROW 8*/}
                <div className=" w-full flex flex-col justify-start items-start">
                  <div className="h-11 w-full px-6 py-3 bg-white justify-start items-center gap-3 inline-flex text-[#6b6a70] text-xs font-semibold font-openSans">Registered Date</div>
                  {filteredDataWithMasterclass.map((item, index) => (
                    <div className={`h-[72px] w-full px-6 py-4 ${index % 2 === 0 ? "bg-[#f5f6f6]" : "bg-white"}  justify-start items-center gap-3 inline-flex text-[#4e4d51] text-sm font-normal font-openSans `}>
                      {/* {moment(item.date_sign_in).format("Dd MMMM YYYY")} */}
                      {moment(item.date_sign_in, "YYYY-MM-DD HH:mm:ss", true).isValid() ? moment(item.date_sign_in).format("DD MMMM YYYY") : "No Date Available"}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          {/* TABLE FOR PARTICIPANTS WITHOUT MASTERCLASS */}
          <div className=" w-full gap-[8px] flex-col justify-start items-start flex">
            <h5 className="self-stretch text-[#6b6a70] text-xs font-semibold font-openSans">Participants without Masterclass</h5>
            <div className="h-fit w-full whitespace-nowrap  100:overflow-x-scroll bg-white rounded-lg shadow border border-[#cfcfd2]  flex-col justify-center items-start gap-[24px] inline-flex">
              <div className=" h-[82px] flex justify-start items-center w-full p-[20px]">
                <div className="self-stretch gap-[12px] h-[42px] 100:hidden md:flex w-[400px]   px-3.5 py-2.5 bg-white rounded-[20px] shadow border border-[#aeaeb2] justify-start items-center  inline-flex text-[#6c757d] text-base font-normal leading-normal">
                  <Search /> <input className="rounded-lg pr-3.5 bg-transparent border-none outline-none w-full h-full" placeholder="Search" />
                </div>
              </div>
              <div className=" self-stretch w-full border-t border-t-[#CDD1D4] flex justify-between items-center ">
                {/* ROW 1*/}
                <div className=" w-full flex flex-col justify-start items-start">
                  <div className="h-11 w-full px-6 py-3 bg-white justify-start items-center gap-3 inline-flex text-[#6b6a70] text-xs font-semibold font-openSans">ID</div>
                  {filteredDataWithoutMasterclass.map((item, index) => (
                    <div className={`h-[72px] w-full px-6 py-4 ${index % 2 === 0 ? "bg-[#f5f6f6]" : "bg-white"}  justify-start items-center gap-3 inline-flex text-[#4e4d51] text-sm font-normal font-openSans `}>{item.id}</div>
                  ))}
                </div>
                {/* ROW 2*/}
                <div className=" w-full flex flex-col justify-start items-start">
                  <div className="h-11 w-full px-6 py-3 bg-white justify-start items-center gap-3 inline-flex text-[#6b6a70] text-xs font-semibold font-openSans">Name</div>
                  {filteredDataWithoutMasterclass.map((item, index) => (
                    <div className={`h-[72px] w-full px-6 py-4 ${index % 2 === 0 ? "bg-[#f5f6f6]" : "bg-white"}  justify-start items-center gap-3 inline-flex text-[#4e4d51] text-sm font-normal font-openSans `}>
                      {item.first_name} {item.last_name}
                    </div>
                  ))}{" "}
                </div>
                {/* ROW 3*/}
                <div className=" w-full flex flex-col justify-start items-start">
                  <div className="h-11 w-full px-6 py-3 bg-white justify-start items-center gap-3 inline-flex text-[#6b6a70] text-xs font-semibold font-openSans">Email</div>
                  {filteredDataWithoutMasterclass.map((item, index) => (
                    <div className={`h-[72px] w-full px-6 py-4 ${index % 2 === 0 ? "bg-[#f5f6f6]" : "bg-white"}  justify-start items-center gap-3 inline-flex text-[#4e4d51] text-sm font-normal font-openSans `}>{item.email}</div>
                  ))}{" "}
                </div>

                {/* ROW 4*/}
                <div className=" w-full flex flex-col justify-start items-start">
                  <div className="h-11 w-full px-6 py-3 bg-white justify-start items-center gap-3 inline-flex text-[#6b6a70] text-xs font-semibold font-openSans">Phone Number</div>
                  {filteredDataWithoutMasterclass.map((item, index) => (
                    <div className={`h-[72px] w-full px-6 py-4 ${index % 2 === 0 ? "bg-[#f5f6f6]" : "bg-white"}  justify-start items-center gap-3 inline-flex text-[#4e4d51] text-sm font-normal font-openSans `}>{item.phone_number}</div>
                  ))}
                </div>

                {/* ROW 5*/}
                <div className=" w-full flex flex-col justify-start items-start">
                  <div className="h-11 w-full px-6 py-3 bg-white justify-start items-center gap-3 inline-flex text-[#6b6a70] text-xs font-semibold font-openSans">Registered Date</div>
                  {filteredDataWithoutMasterclass.map((item, index) => (
                    <div className={`h-[72px] w-full px-6 py-4 ${index % 2 === 0 ? "bg-[#f5f6f6]" : "bg-white"}  justify-start items-center gap-3 inline-flex text-[#4e4d51] text-sm font-normal font-openSans `}>
                      {moment(item.date_created, "YYYY-MM-DD HH:mm:ss", true).isValid() ? moment(item.date_created).format("DD MMMM YYYY") : "No Date Available"}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default UserManagement;
