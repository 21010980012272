// import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import Select from "react-select";
import Header from "../Header";
import illustration1 from "../../Images/photos/illustration1.png";
import illustration2 from "../../Images/photos/illustration2.png";
import illustration3 from "../../Images/photos/illustration3.png";
import discuss1 from "../../Images/photos/discussion1.png";
import discuss2 from "../../Images/photos/discussion2.png";
import comment from "../../Images/svg/comment.svg";
import like from "../../Images/svg/like.svg";
import reply from "../../Images/svg/reply.svg";
import cancel from "../../Images/svg/Cancel.svg";
import Stories from "./NavbarComponents/Stories";
import NewsLetter from "./NavbarComponents/NewsLetter";
import Footer from "./NavbarComponents/Footer";
import up from "../../Images/svg/arrowUp.svg";
import tick from "../../Images/svg/ticked.svg";
import search from "../../Images/svg/search.svg";
import group1 from "../../Images/photos/group1.png";
import group2 from "../../Images/photos/group2.png";
import group3 from "../../Images/photos/group3.png";
import group4 from "../../Images/photos/group4.png";
import group5 from "../../Images/photos/group5.png";
import group6 from "../../Images/photos/group6.png";
import group7 from "../../Images/photos/group7.png";
import group8 from "../../Images/photos/group8.png";
import group9 from "../../Images/photos/group9.png";
import group10 from "../../Images/photos/group10.png";
import group11 from "../../Images/photos/group11.png";
import group12 from "../../Images/photos/group12.png";
import parent from "../../Images/photos/parents.png";
import calendar from "../../Images/svg/calendar.svg";
import upload from "../../Images/svg/download.svg";
import highlight1 from "../../Images/photos/highlight1.png";
import highlight2 from "../../Images/photos/highlight2.png";
import highlight3 from "../../Images/photos/highlight3.png";
import moment1 from "../../Images/photos/moment1.png";
import moment2 from "../../Images/photos/moment2.png";
import moment3 from "../../Images/photos/moment3.png";
import moment4 from "../../Images/photos/moment4.png";
import moment5 from "../../Images/photos/moment5.png";
import moment6 from "../../Images/photos/moment6.png";
import moment7 from "../../Images/photos/moment7.png";
import moment8 from "../../Images/photos/moment8.png";
import moment9 from "../../Images/photos/moment9.png";
import moment10 from "../../Images/photos/moment10.png";
import moment11 from "../../Images/photos/moment11.png";
import moment12 from "../../Images/photos/moment12.png";
import download from "../../Images/svg/light.svg";
import pick from "../../Images/svg/img.svg";

const localGroup = [
  {
    id: 1,
    picture: group1,
    title: "Lagos Parents",
    description: "Connect with other parents in NYC for playdates, local events, and support.",
  },
  {
    id: 2,
    picture: group2,
    title: "Breastfeeding Support",
    description: "Join this group to share experiences and tips on breastfeeding.",
  },
  {
    id: 3,
    picture: group3,
    title: "Play Group",
    description: "Join this group to share experiences and tips on breastfeeding.",
  },
  {
    id: 4,
    picture: group4,
    title: "Toys",
    description: "Join this group to share experiences and tips on breastfeeding.",
  },
  {
    id: 5,
    picture: group5,
    title: "Toddlers Soccer ",
    description: "Join this group to share experiences and tips on breastfeeding.",
  },
  {
    id: 6,
    picture: group6,
    title: "Dance for Kids ",
    description: "Join this group to share experiences and tips on breastfeeding.",
  },
  {
    id: 7,
    picture: group7,
    title: "Parent Community",
    description: "Join fellow parents in your locality to share experiences and organize local activities.",
  },
  {
    id: 8,
    picture: group8,
    title: "New Mums",
    description: "Join this group to share experiences and tips on breastfeeding.",
  },
  {
    id: 9,
    picture: group9,
    title: "Babywearing Enthusiasts",
    description: "Learn about different babywearing techniques and share your experiences.",
  },
  {
    id: 10,
    picture: group10,
    title: "Homeschooling Parents",
    description: "A group for parents who homeschool their children to share resources and support each other.",
  },
  {
    id: 11,
    picture: group11,
    title: "Eco-Friendly Parenting",
    description: "Connect with parents who are passionate about raising children in an eco-friendly and sustainable way.",
  },
  {
    id: 12,
    picture: group12,
    title: "Single Parents Network",
    description: "A supportive community for single parents to share their unique experiences and resources.",
  },
];
const highlightStories = [
  {
    id: 1,
    picture: highlight1,
    name: "John Makinde",
    description: "John shares his journey of balancing work and parenting twins, and how he found support in the community.",
  },
  {
    id: 2,
    picture: highlight2,
    name: "John Makinde",
    description: "John shares his journey of balancing work and parenting twins, and how he found support in the community.",
  },
  {
    id: 3,
    picture: highlight3,
    name: "John Makinde",
    description: "John shares his journey of balancing work and parenting twins, and how he found support in the community.",
  },
];
const moments = [
  {
    id: 1,
    picture: moment1,
    title: "First Steps",
  },
  {
    id: 2,
    picture: moment2,
    title: "First Smile",
  },
  {
    id: 3,
    picture: moment3,
    title: "Family Outings",
  },
  {
    id: 4,
    picture: moment4,
    title: "Holiday Celebrations",
  },
  {
    id: 5,
    picture: moment5,
    title: "Bath Time Fun",
  },
  {
    id: 6,
    picture: moment6,
    title: "Baby’s First Meal",
  },
  {
    id: 7,
    picture: moment7,
    title: "Sibling Love",
  },
  {
    id: 8,
    picture: moment8,
    title: "Joy",
  },
  {
    id: 9,
    picture: moment9,
    title: "Outdoor Adventures",
  },
  {
    id: 10,
    picture: moment10,
    title: "Naptime Snuggles",
  },
  {
    id: 11,
    picture: moment11,
    title: "Playtime Joy",
  },
  {
    id: 12,
    picture: moment12,
    title: "Playtime Joy",
  },
];
const momentTheme = moments.map((item) => ({
  label: item.title,
  value: item.title,
}));
function Community() {
  const [activeCategory, setActiveCategory] = useState("discussion-forums");
  const [respond, setRespond] = useState(false);
  // const [save, setSave] = useState(false);
  const [save2, setSave2] = useState(false);
  const [save3, setSave3] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const [join, setJoin] = useState(false);
  const [uploadMoment, setUploadMoment] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 600) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // This makes the scroll smooth
    });
  };
  const toggleCategory = (category) => {
    setActiveCategory(category);
  };
  const discussion = [
    {
      id: 1,
      title: "Handling Tantrums",
      picture: discuss1,
      user: "Harrison12349",
      message: "Share your tips and strategies for managing toddler tantrums.Share your tips and strategies for managing toddler tantrums.",
      comments: "100",
      likes: "30,000 ",
      time: "5 minutes",
    },
    {
      id: 2,
      title: "Best Strollers of 2024",
      picture: discuss2,
      user: "shade12349",
      message: "Discuss the pros and cons of different stroller brands and models.",
      comments: "100",
      likes: "30,000 ",
      time: "5 minutes",
    },
  ];
  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    setSelectedImage(file);
    console.log(selectedImage);
    // if (file) {
    //   const reader = new FileReader();
    //   reader.onloadend = () => {
    //     // const base64String = reader.result
    //     const base64String = reader.result.split(",")[1]; // Remove the prefix
    //     setSelectedImage(base64String);

    //     // formik.setFieldValue("photo", base64String);
    //     // console.log("Base64 String:", base64String);
    //   };
    //   reader.readAsDataURL(file);
    // }
  };
  return (
    <>
      <Header />
      <div className="w-full h-fit gap-[48px]  pt-[32px] 100:px-[32px] lg:px-[64px] flex-col flex justify-center items-center">
        <div className=" flex-col  100:w-[90%] justify-center sm:w-[488px] text-center items-center gap-[10px] inline-flex">
          <h3 className="text-[#3c3b3e] 100:text-[40px] md:text-[64px] font-semibold font-playfair">Welcome to Our Community</h3>
          <p className=" text-center text-[#6b6a70] text-lg font-normal font-openSans">Connect with fellow parents, share experiences, and find support in our vibrant community.</p>
        </div>
        <div className=" w-full flex justify-center items-end">
          <div>
            <img className="grow w-full basis-0 shrink" src={illustration1} alt="illustration" />
          </div>
          <div>
            <img className="grow w-full basis-0 shrink" src={illustration2} alt="illustration" />
          </div>{" "}
          <div>
            <img className="grow w-full basis-0 shrink" src={illustration3} alt="illustration" />
          </div>
        </div>
      </div>
      <div className="h-fit w-full py-16 bg-[#fcf3f8] flex-col justify-center items-center inline-flex">
        <div className="xl:w-[1280px] 100:w-[90%] h-fit px-[32px] flex-col justify-start items-start gap-8 inline-flex">
          <div className="100:py-[10px] md:py-0  w-full flex flex-col justify-start items-start">
            <div className="  flex text-[#6B6A70]  text-sm font-semibold font-openSans items-start gap-[48px] justify-start whitespace-nowrap overflow-x-scroll overflow-y-hidden 100:w-full lg:w-fit">
              <button className={`h-[37px] pb-[8px] ${activeCategory === "discussion-forums" ? " border-b-[2px] border-b-[#fe8734] text-[#fe8734]" : ""}`} onClick={() => toggleCategory("discussion-forums")}>
                Discussion Forums
              </button>
              <button className={`h-[37px] pb-[8px] ${activeCategory === "local-groups" ? " border-b-[2px] border-b-[#fe8734] text-[#fe8734]" : ""}`} onClick={() => toggleCategory("local-groups")}>
                Local Groups
              </button>{" "}
              <button className={`h-[37px] pb-[8px] ${activeCategory === "member-spotlights" ? " border-b-[2px] border-b-[#fe8734] text-[#fe8734]" : ""}`} onClick={() => toggleCategory("member-spotlights")}>
                Member Spotlights
              </button>{" "}
              <button className={`h-[37px] pb-[8px] ${activeCategory === "photo-gallery" ? " border-b-[2px] border-b-[#fe8734] text-[#fe8734]" : ""}`} onClick={() => toggleCategory("photo-gallery")}>
                Photo Gallery
              </button>
            </div>
            <div className="w-full h-px bg-[#cfcfd2]" />
          </div>
          <div className=" w-full">
            {activeCategory === "discussion-forums" && (
              <>
                <div className=" w-full 100:flex-col gap-[32px] xl:flex-row flex justify-start items-start">
                  {discussion.map((item) => (
                    <div key={item.id} className="flex flex-col justify-start items-start gap-[16px]">
                      <h2 className="text-[#3c3b3e] 100:text-[28px] iphone:text-[32px] font-medium font-playfair">{item.title}</h2>
                      <div className=" xl:w-[592px] xl:h-[239px] 100:w-full">
                        <img className="grow  basis-0 shrink w-full rounded-[8px]" src={item.picture} alt={item.title} />
                      </div>
                      <div className=" w-full gap-[14px] flex flex-col justify-start items-start">
                        <div className=" flex-col justify-start items-start gap-2 inline-flex">
                          <div className=" justify-center w-fit items-center gap-1.5 inline-flex">
                            <div className="p-[7px] w-[24px] h-[24px] text-center text-[#f5f5f6] text-xs font-bold font-openSans leading-[9px] bg-[#fe8734] rounded-[100px] flex-col justify-center items-center inline-flex">
                              {item.user.charAt(0).toUpperCase()}
                            </div>
                            <h5 className=" text-[#86858b] text-xs font-medium font-playfair">By {item.user}</h5>
                          </div>
                          <div className=" w-full">
                            <p className="text-[#4e4d51] text-base font-openSans font-normal">{item.message}</p>
                          </div>
                        </div>
                        <div className="w-fit 100:flex-col iphone:flex-row justify-start items-center 100:gap-[10px] iphone:gap-4 inline-flex">
                          <section className="self-stretch   justify-start py-[2px] items-center gap-[4px] flex">
                            <img src={comment} alt="" /> <h5 className="text-[#4e4d51] text-xs font-normal font-openSans">{item.comments} Comments</h5>
                          </section>
                          <section className="self-stretch   justify-start py-[2px] items-center gap-[4px] flex">
                            <img src={like} alt="" /> <h5 className="text-[#4e4d51] text-xs font-normal font-openSans">{item.likes} Likes</h5>
                          </section>
                          <section onClick={() => setRespond(true)} className="self-stretch cursor-pointer   justify-start py-[2px] items-center gap-[4px] flex">
                            <img src={reply} alt="" /> <h5 className="text-[#4e4d51] text-xs font-normal font-openSans">Reply</h5>
                          </section>
                        </div>
                        <div className="self-stretch text-[#86858b] text-xs font-normal font-openSans">{item.time} minutes ago</div>
                      </div>
                    </div>
                  ))}
                </div>
              </>
            )}
            {activeCategory === "local-groups" && (
              <>
                <div className=" w-full flex-col justify-center items-start flex  gap-[32px] py-[24px]">
                  <div className=" flex-col gap-[32px] w-full flex justify-start items-start">
                    <div className="phone:w-[321px] 100:w-full h-[39px] pl-[23px] py-2.5 rounded-[20px] border border-[#aeaeb2] justify-start items-start gap-2.5 flex">
                      <img src={search} alt="Search" />
                      <input placeholder="Search" className="text-[#6b6a70] text-sm font-normal font-openSans  w-full border-none bg-transparent  outline-none  h-full" />
                    </div>
                    <div className=" w-full flex flex-wrap justify-start items-start gap-[32px] flex-1">
                      {localGroup.map((item) => (
                        <div key={item.id} className="w-[174px] flex-grow  h-fit flex-col justify-start items-center gap-2.5 inline-flex">
                          <div className="rounded-[100px] justify-start items-center gap-2.5 inline-flex">
                            <img className="w-[140px] h-[140px] rounded-[100px]" alt={item.title} src={item.picture} />
                          </div>
                          <div className=" w-full flex-col justify-center items-center gap-4 inline-flex">
                            <div className=" w-full flex-col justify-start items-center gap-1 inline-flex">
                              <h3 className="self-stretch text-center text-[#4e4d51] text-base font-semibold font-openSans">{item.title}</h3>
                              <p className="self-stretch text-center text-[#86858b] text-[10px] font-normal font-openSans">{item.description}</p>
                            </div>
                            <button onClick={() => setJoin(true)} className="text-center text-[#fe8734] text-sm font-semibold font-openSans">
                              Join now
                            </button>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </>
            )}
            {activeCategory === "member-spotlights" && (
              <>
                <div className=" w-full flex-col justify-start items-start flex  gap-[32px] py-[24px]">
                  <h2 className="self-stretch text-[#3c3b3e] text-[32px] font-semibold font-playfair">Highlight Stories</h2>
                  <div className="100:w-full 100:flex-wrap xl:w-[1216px] flex-w xl:flex-nowrap 100:flex-1  justify-start items-start gap-8 flex">
                    {highlightStories.map((item) => (
                      <>
                        <div key={item.id} className=" 100:w-full lg:w-[378px] flex-grow  h-fit flex flex-col items-start justify-start gap-[16px]">
                          <div className=" w-full ">
                            <img className=" w-full" src={item.picture} alt={item.name} />
                          </div>
                          <div className="w-full flex-col justify-start items-start gap-2.5 flex">
                            <h4 className="text-center text-[#3c3b3e] text-2xl font-semibold font-openSans">John Makinde</h4>
                            <p className="self-stretch text-[#6b6a70] text-base font-normal font-openSans leading-[25px]">John shares his journey of balancing work and parenting twins, and how he found support in the community.</p>
                          </div>
                        </div>
                      </>
                    ))}
                  </div>
                </div>
              </>
            )}
            {activeCategory === "photo-gallery" && (
              <>
                <div className=" w-full flex-col justify-center items-center flex  gap-[32px] py-[24px]">
                  <div className=" 100:flex-col 100:gap-[32px] lg:gap-0 md:flex-row  w-full self-stretch flex justify-between items-center">
                    <div className=" 100:w-full lg:w-[447px] flex-col justify-start items-start gap-2.5 inline-flex">
                      <h3 className=" text-[#3c3b3e] text-[32px] font-semibold font-playfair">Share Your Moments</h3>
                      <p className=" text-[#4e4d51] text-base font-normal font-openSans">Upload photos of your baby’s first steps and celebrate this milestone with the community.</p>
                    </div>
                    <div className=" 100:w-full flex justify-end items-end lg:w-fit">
                      <button onClick={() => setUploadMoment(true)} className="h-9 w-fit text-[#f5f5f6] gap-2 text-sm font-semibold font-openSans  px-6 py-2 bg-[#fe8734] rounded-[20px] justify-center items-center inline-flex">
                        Upload photos <img src={upload} alt="upload" />
                      </button>
                    </div>
                  </div>
                  <div className=" 100:w-full flex xl:w-[1216px]  flex-1 flex-wrap justify-start items-start gap-[32px] ">
                    {moments.map((item) => (
                      <div key={item.id} className="w-[280.97px]  flex-grow h-fit flex-col justify-start items-start gap-[16px] inline-flex">
                        <div className=" w-full">
                          <img className=" w-full" alt={item.title} src={item.picture} />
                        </div>
                        <h3 className="text-[#4e4d51] text-base font-semibold font-openSans">{item.title}</h3>
                      </div>
                    ))}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <Stories />
      <NewsLetter />
      <Footer />
      {showButton && (
        <>
          <div onClick={scrollToTop} className="h-10 shadow z-40 100:right-[40px] top-[80vh] lg:right-[80px] p-2.5 cursor-pointer fixed bg-[#dff4ff] rounded-[100px] justify-center items-center gap-2.5 inline-flex">
            <img src={up} alt="arrow" />
          </div>
        </>
      )}
      {respond && (
        <>
          <div className=" fixed z-[50] flex justify-center items-center w-full h-full bg-black bg-opacity-40 top-0 left-0">
            <div className=" 100:w-[80%] h-fit sm:w-[500px]  px-4 py-6 bg-[#f5f5f6] rounded-[20px] flex-col justify-start items-center gap-[40px] inline-flex">
              {/* <div className="w-full px-2 justify-between items-center inline-flex">
                <div className="grow shrink basis-0 text-[#3c3b3e] text-xl font-semibold font-openSans">{save ? <></> : <>Add Reply</>}</div>

                <div onClick={() => setRespond(false)} className="w-12 cursor-pointer h-12 justify-center flex  items-center bg-black/30 rounded-[100px] backdrop-blur-[31.63px]  ">
                  <img className=" " src={cancel} alt="Cancel" />
                </div>
              </div>
              {save ? (
                <>
                  <div className=" flex-col  w-full  justify-center items-center gap-4 inline-flex">
                    <div className="w-12 h-12 flex justify-center items-center bg-[#ade4ac] rounded-[28px] border-8 border-[#e7f6ec]">
                      <img src={tick} alt="tick" />
                    </div>
                    <div className=" w-full px-2 flex-col justify-center items-center gap-2 inline-flex">
                      <h3 className="text-[#3c3b3e] text-xl font-semibold font-openSans">Added Successfully</h3>
                      <p className=" text-center text-[#6b6a70] text-sm font-normal font-openSans">Reply added successfully</p>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className=" px-6 pt-6 pb-[37px] w-full flex-col justify-start items-start gap-4 inline-flex">
                    <div className=" float-start justify-start w-full items-start gap-1.5 flex">
                      <div className="p-[7px] w-[30px] h-[30px] text-center text-[#f5f5f6] text-[16px] font-bold font-openSans leading-[9px] bg-[#fe8734] rounded-[100px] flex-col justify-center items-center inline-flex">H</div>
                      <h5 className=" text-[#4e4d51] text-base font-medium font-playfair">By Harrison12349</h5>
                    </div>
                    <textarea
                      placeholder="What's on your mind, Harrison12349?"
                      className="h-[152px] outline-[#fe8734] w-full px-3.5 py-2.5 text-[#4e4d51] placeholder:text-[#aeaeb2] text-sm font-normal bg-white rounded-lg shadow border border-[#e6e6e7] justify-start items-start gap-2 inline-flex"
                    />
                  </div>
                </>
              )}
              <div className=" w-full flex justify-center items-center">
                <button>
                  <div onClick={() => setSave(true)} className="w-[250px] h-12 px-8 py-2 bg-[#ffd4a9] rounded-[20px] justify-center text-[#f5f5f6] text-xl font-semibold font-openSans items-center inline-flex">
                    {save ? <>Ok</> : <>Post</>}
                  </div>
                </button>
              </div> */}
              <div className=" w-full flex justify-between items-center">
                <div className="grow shrink basis-0 text-[#3c3b3e] text-xl font-semibold font-openSans"></div>

                <div onClick={() => setRespond(false)} className="w-12 cursor-pointer h-12 justify-center flex  items-center bg-black/30 rounded-[100px] backdrop-blur-[31.63px]  ">
                  <img className=" " src={cancel} alt="Cancel" />
                </div>
              </div>
              <div className=" w-full px-2 flex-col justify-center items-center gap-2 inline-flex">
                <h3 className="text-[#3c3b3e] text-xl font-semibold font-openSans">Coming Soon</h3>
                <p className=" w-[90%] text-center text-[#6b6a70] text-sm font-normal font-openSans">We're bringing exciting new features soon. Stay tuned for updates on our forum, community, and group discussions! </p>
              </div>
              <div className=" w-full flex justify-center items-center">
                <button onClick={() => setRespond(false)}>
                  <div className="w-[250px] h-12 px-8 py-2 bg-[#fe8734] rounded-[20px] justify-center text-[#f5f5f6] text-xl font-semibold font-openSans items-center inline-flex">Ok</div>
                </button>
              </div>
            </div>
          </div>
        </>
      )}
      {join && (
        <>
          <div className=" fixed z-[50] flex justify-center items-center w-full h-full bg-black bg-opacity-40 top-0 left-0">
            <div className={`100:w-[85%] sm:w-[500px] ${save2 ? " h-fit" : "p-2.5  h-[619px] gap-2"}  bg-[#f5f5f6] rounded-[20px] flex-col justify-start items-center  inline-flex`}>
              {save2 ? (
                <>
                  <div className=" w-full p-[24px]  flex flex-col justify-center items-center gap-[32px]">
                    <div className=" flex justify-end items-end w-full">
                      <div onClick={() => setJoin(false)} className="w-12 cursor-pointer h-12  justify-center flex  items-center bg-black/30 rounded-[100px] backdrop-blur-[31.63px]  ">
                        <img className=" " src={cancel} alt="Cancel" />
                      </div>
                    </div>
                    <div className=" flex-col  w-full  justify-center items-center gap-4 inline-flex">
                      {/* <div className="w-12 h-12 flex justify-center items-center bg-[#ade4ac] rounded-[28px] border-8 border-[#e7f6ec]">
                        <img src={tick} alt="tick" />
                      </div> */}
                      <div className=" w-full px-2 flex-col justify-center items-center gap-2 inline-flex">
                        <h3 className="text-[#3c3b3e] text-xl font-semibold font-openSans">Coming Soon</h3>
                        <p className=" w-[90%] text-center text-[#6b6a70] text-sm font-normal font-openSans">We're bringing exciting new features soon. Stay tuned for updates on our forum, community, and group discussions! </p>
                      </div>
                    </div>
                    <div className=" w-full flex justify-center items-center">
                      <button onClick={() => setJoin(false)}>
                        <div className="w-[250px] h-12 px-8 py-2 bg-[#fe8734] rounded-[20px] justify-center text-[#f5f5f6] text-xl font-semibold font-openSans items-center inline-flex">Ok</div>
                      </button>
                    </div>{" "}
                  </div>
                </>
              ) : (
                <>
                  <div style={{ backgroundImage: `url(${parent})`, backgroundSize: "cover" }} className=" p-[6px] w-full h-[266.67px] rounded-[20px]">
                    <div className=" flex justify-end items-end w-full">
                      <div onClick={() => setJoin(false)} className="w-12 cursor-pointer h-12  justify-center flex  items-center bg-white/30 rounded-[100px] backdrop-blur-[31.63px]  ">
                        <img className=" " src={cancel} alt="Cancel" />
                      </div>
                    </div>
                  </div>
                  <div className="h-full w-full overflow-y-scroll p-6 bg-[#f0faff] rounded-[20px] flex-col justify-start items-start gap-[32px] inline-flex">
                    <div className="h-fit w-full flex-col justify-start items-start gap-4 inline-flex">
                      <div className="w-full flex-col justify-start items-start gap-2 inline-flex">
                        <h2 className="text-[#3c3b3e] text-2xl font-semibold font-openSans">Single Parents Network</h2>
                        <p className="self-stretch text-[#86858b] text-sm font-normal leading-[22px] font-openSans">
                          A supportive community for single parents to share their unique experiences, offer advice, and find resources. This group provides a space to connect with others who understand the challenges and rewards of single parenting.
                        </p>
                      </div>
                      <div className="h-px w-full bg-[#e6e6e7]" />
                      <div className="h-fit w-full flex-col justify-start items-start gap-3.5 inline-flex">
                        <h3 className="text-[#4e4d51] text-lg font-semibold font-openSans">Upcoming Events</h3>
                        <div className="w-full flex-col justify-start items-start gap-2.5 inline-flex">
                          <h4 className="text-[#86858B] font-normal text-xs font-openSans ">
                            {" "}
                            <span className="text-[#6B6A70] font-semibold">Event:</span> Single Parents Support Group Meeting
                          </h4>
                          <h4 className="text-[#86858B] font-normal text-xs font-openSans ">
                            {" "}
                            <span className="text-[#6B6A70] font-semibold">Location:</span> Local Community Hall
                          </h4>
                          <div className="bg-[#FAE9F2] rounded-[20px] w-fit h-fit gap-[4px] px-[8px] py-[4px] flex justify-center items-center">
                            <img src={calendar} alt="calenadr" />

                            <p className="text-[#e05493] text-[10px] font-semibold font-openSans">Sunday, October 20th, 2024</p>
                          </div>
                        </div>
                      </div>
                      <div className="h-px w-full bg-[#e6e6e7]" />
                      <div className="h-fit w-full flex-col justify-start items-start gap-3.5 inline-flex">
                        <h3 className="text-[#4e4d51] text-lg font-semibold font-openSans">Discussion Topics</h3>
                        <div className="flex-wrap justify-start items-start gap-2 flex">
                          <div className="text-[#aeaeb2] text-[10px] font-semibold font-openSans px-2 py-1 bg-[#f5f5f6] rounded-[20px] justify-end items-center gap-1 flex">Balancing Work and Parenting</div>
                          <div className="text-[#aeaeb2] text-[10px] font-semibold font-openSans px-2 py-1 bg-[#f5f5f6] rounded-[20px] justify-end items-center gap-1 flex">Co-Parenting Strategies</div>
                          <div className="text-[#aeaeb2] text-[10px] font-semibold font-openSans px-2 py-1 bg-[#f5f5f6] rounded-[20px] justify-end items-center gap-1 flex">Self-Care Tips for Single Parents</div>
                        </div>
                      </div>
                      <div className=" w-full flex justify-center items-center">
                        <button onClick={() => setSave2(true)}>
                          <div className="w-[250px] h-12 px-8 py-2 bg-[#fe8734] rounded-[20px] justify-center text-[#f5f5f6] text-xl font-semibold font-openSans items-center inline-flex">Join Now</div>
                        </button>
                      </div>{" "}
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </>
      )}
      {uploadMoment && (
        <>
          <div className=" fixed z-[50] flex justify-center items-center w-full h-full bg-black bg-opacity-40 top-0 left-0">
            <div className=" 100:w-[80%] h-fit sm:w-[500px]  px-4 py-6 bg-[#f5f5f6] rounded-[20px] flex-col justify-start items-center gap-[40px] inline-flex">
              <div className="w-full px-2 justify-between items-center inline-flex">
                <div className="grow shrink basis-0 text-[#3c3b3e] text-xl font-semibold font-openSans">{save3 ? <></> : <>Upload Your Special Moments</>}</div>

                <div onClick={() => setUploadMoment(false)} className="w-12 cursor-pointer h-12 justify-center flex  items-center bg-black/30 rounded-[100px] backdrop-blur-[31.63px]  ">
                  <img className=" " src={cancel} alt="Cancel" />
                </div>
              </div>
              {save3 ? (
                <>
                  <div className=" flex-col  w-full  justify-center items-center gap-4 inline-flex">
                    <div className="w-12 h-12 flex justify-center items-center bg-[#ade4ac] rounded-[28px] border-8 border-[#e7f6ec]">
                      <img src={tick} alt="tick" />
                    </div>
                    <div className=" w-full px-2 flex-col justify-center items-center gap-2 inline-flex">
                      <h3 className="text-[#3c3b3e] text-xl font-semibold font-openSans">Uploaded Successfully</h3>
                      <p className=" text-center text-[#6b6a70] text-sm font-normal font-openSans">Moments uploaded successfully</p>
                    </div>
                  </div>
                  <div className=" w-full flex justify-center items-center">
                    <button>
                      <div className="w-[250px] h-12 px-8 py-2 bg-[#fe8734] rounded-[20px] justify-center text-[#f5f5f6] text-xl font-semibold font-openSans items-center inline-flex">Ok</div>
                    </button>
                  </div>
                </>
              ) : (
                <>
                  <form className=" px-6 pt-6 pb-[37px] rounded-[20px] flex-col justify-start items-start gap-4 inline-flex">
                    <div className=" w-full flex justify-center gap-[32px]">
                      <label className="flex-col justify-start items-start gap-1.5 inline-flex text-[#4e4d51] text-sm font-semibold font-openSans" htmlFor="First name">
                        First name
                        <input type="name" placeholder="First name" className="outline-[#fe8734] h-[43px] w-full pl-4 pr-3.5 py-3 bg-white rounded-[20px] shadow border border-[#aeaeb2] justify-start items-center" />
                      </label>
                      <label className="flex-col  justify-start items-start gap-1.5 inline-flex text-[#4e4d51] text-sm font-semibold font-openSans" htmlFor="Last name">
                        Last name
                        <input type="name" placeholder="Last name" className="outline-[#fe8734] h-[43px] w-full pl-4 pr-3.5 py-3 bg-white rounded-[20px] shadow border border-[#aeaeb2] justify-start items-center" />
                      </label>
                    </div>
                    <label className="flex-col w-full justify-start items-start gap-1.5 inline-flex text-[#4e4d51] text-sm font-semibold font-openSans" htmlFor="Email Address">
                      Email
                      <input type="email" placeholder="Email Address" className="outline-[#fe8734] h-[43px] w-full pl-4 pr-3.5 py-3 bg-white rounded-[20px] shadow border border-[#aeaeb2] justify-start items-center" />
                    </label>
                    <label className="flex-col w-full justify-start items-start gap-1.5 inline-flex text-[#4e4d51] text-sm font-semibold font-openSans" htmlFor="moments">
                      Theme
                      <Select
                        className=" placeholder-ellipsis placeholder-gray-500 w-full outline-[#fe8734] "
                        theme={(theme) => ({
                          ...theme,
                          // placeholder: {
                          //   ...theme.placeholder,
                          //   color: "#6B6A70",
                          // },
                          colors: {
                            ...theme.colors,
                            primary25: "#fff6ed",
                            placeholder: "#6B6A70",
                            primary: "#fe8734",
                          },
                        })}
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            width: "100%",
                            background: "#fff",
                            borderRadius: "20px",
                            height: "43px",
                            padding: "3px",
                            boxShadow: "0px 1px 3px 0px rgb(0,0, 0, 0.2)",
                            borderColor: "#aeaeb2",
                            borderWidth: "1px",
                            outline: "1px",
                            fontFamily: "Open Sans",
                            fontWeight: "500",
                            fontSize: "14px",
                          }),
                        }}
                        placeholder="Choose a theme that best describes your photo"
                        options={momentTheme}
                      />
                      {/* <select name="moments" id="moment" placeholder="Email Address" className="outline-[#fe8734] h-[43px] w-full pl-4  py-3 bg-white rounded-[20px] shadow border border-[#aeaeb2] justify-start items-center">
                    {momentTheme.map((item) => (
                      <> */}
                      {/* <div  className=" "> */}
                      {/* <option key={item.id} value={item.title} className="w-full p-[20px] bg-[] ">
                          {item.title}
                        </option> */}
                      {/* </div> */}
                      {/* </>
                    ))} */}
                      {/* </select> */}
                    </label>
                    <label className="flex-col w-full justify-start items-start gap-1.5 inline-flex text-[#4e4d51] text-sm font-semibold font-openSans" htmlFor="Upload Image">
                      Upload Image
                      <div className="w-full">
                        <div className="file-input-container w-full ">
                          <input onChange={handleFileChange} type="file" id="file-input" className="hidden" />
                          <label style={{ width: "100%" }} htmlFor="file-input">
                            <div className="outline-[#fe8734] h-[123px] w-full pl-4 pr-3.5 py-3 bg-white rounded-[20px] shadow border border-[#aeaeb2] justify-start items-center">
                              <div className="flex-col justify-center items-center flex">
                                <div className="w-10 h-10 p-2 bg-[#E6E6E7] rounded-[28px] border-[6px] border-[#F5F5F6] justify-center items-center inline-flex">
                                  <img src={download} alt="upload" />
                                </div>
                                <div className="self-stretch justify-center items-start gap-1 flex-col flex">
                                  <div className="justify-center w-full font-openSans flex-col items-center gap-2 flex">
                                    <div className="text-[#fe8734]   text-sm font-normal ">
                                      Click to upload <span className="text-[#6b6a70]">or drag and drop</span>
                                    </div>
                                    <div className=" text-center text-[#6C757D] text-xs font-normal  ">(max. 2mb)</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </label>
                        </div>
                      </div>
                    </label>
                    {selectedImage && (
                      <>
                        <div className="h-[94px] w-full p-4 bg-white rounded-[20px] border border-[#ffebd4] justify-start items-start gap-1 inline-flex">
                          <div className="w-8 h-8  bg-[#ffebd4] flex justify-center items-center rounded-[28px] border-4 border-[#fff6ed]">
                            <img src={pick} alt="" />
                          </div>
                          <div className=" w-full flex flex-col justify-start items-start gap-[4px]">
                            <div className=" flex-col justify-start items-start inline-flex">
                              <h4 className="self-stretch text-[#4e4d51] text-sm font-semibold font-openSans">Family.jpg</h4>
                              <h5 className="self-stretch text-[#6b6a70] text-sm font-normal font-openSans">720 KB</h5>
                            </div>
                            <div className=" flex gap-[12px] w-full">
                              <div className="w-full h-2 bg-[#fff6ed] rounded-lg ">
                                <div className="w-full h-2 bg-[#fe8734] rounded" />
                              </div>
                              <span className="text-[#4e4d51] text-sm font-semibold font-openSans">100%</span>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                    <div className=" w-full flex justify-center items-center">
                      <button onClick={() => setSave3(true)}>
                        <div className="w-[250px] h-12 px-8 py-2 bg-[#fe8734] rounded-[20px] justify-center text-[#f5f5f6] text-xl font-semibold font-openSans items-center inline-flex">Submit</div>
                      </button>
                    </div>
                  </form>
                </>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default Community;
