import { Link } from "react-router-dom";
import logo from "../Images/photos/ThebabyConventionColorLogo.png";
import arrow from "../Images/svg/arrowleft.svg";
import tick from "../Images/svg/ticked.svg";

function Successful() {
  return (
    <>
      <div className=" w-full flex flex-col justify-center items-center px-[10px] py-[40px] ">
        <div className=" h-[100px] bg-white/40 w-full top-0 left-[40px] 100:pr-[70px] right-[40px] flex fixed justify-between items-center">
          <Link to="/admin/user-management">
            <div className="w-fit flex justify-center items-center gap-2">
              <div>
                <img className="w-full" src={arrow} alt="back" />
              </div>
              <div className="text-[#fe8734] 100:hidden md:block text-base font-semibold font-openSans">Back</div>
            </div>
          </Link>
          <div className=" 100:w-[60px] iphone:w-[80px] iphone:h-[54.89px]">
            <img className="w-full" src={logo} alt="The Baby Convention" />
          </div>
          <div className="w-[120px] 100:hidden md:block"></div>
        </div>
        <div className="h-fit 100:w-[85%]  sm:w-[498px] mt-[130px] px-4 py-6 bg-[#f5f5f6] rounded-3xl shadow flex-col justify-start items-start gap-2.5 inline-flex">
          <div className=" flex-col  w-full  justify-center items-center gap-[24px] inline-flex">
            <div className="w-12 h-12 flex justify-center items-center bg-[#ade4ac] rounded-[28px] border-8 border-[#e7f6ec]">
              <img src={tick} alt="tick" />
            </div>
            <div className=" w-full px-2 flex-col justify-center items-center gap-2 inline-flex">
              <h3 className="text-[#3c3b3e] text-xl font-semibold font-openSans">Successful</h3>
              <p className=" text-center text-[#6b6a70] text-sm font-normal font-openSans">Payment has been made successfully</p>
            </div>
            <div className=" w-full flex justify-center items-center">
              <button>
                <div
                  //   onClick={() => {
                  //     setSave(false);
                  //     setLoading(false);
                  //   }}
                  className="w-[250px] h-12 px-8 py-2 bg-[#fe8734] rounded-[20px] justify-center text-[#f5f5f6] text-xl font-semibold font-openSans items-center inline-flex"
                >
                  Ok
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Successful;
