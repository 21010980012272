import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { useEffect, useState } from "react";
import logo from "../Images/photos/ThebabyConventionColorLogo.png";
import arrow from "../Images/svg/arrowleft.svg";
import pic from "../Images/photos/form.png";
import { Link } from "react-router-dom";
import { ReactComponent as Star } from "../Images/svg/star.svg";
import { useParams } from "react-router-dom";
// import cancel from "../Images/svg/Cancel.svg";
// import tick from "../Images/svg/ticked.svg";

const earlyBird = [
  { id: 1, title: "4 sqm inside the hall - ₦122,000" },
  { id: 2, title: "4 sqm outside the hall - ₦122,000" },
  // { id: 3, title: "4 square meters - ₦122,000" },
];
const regular = [
  { id: 1, title: "4 sqm inside the hall - ₦152,000" },
  { id: 2, title: "4 sqm outside the hall - ₦152,000" },
  // { id: 3, title: " All booths outside the hall - ₦152,000" },
];
function Payment() {
  const validationSchema = Yup.object({
    action: Yup.string().notRequired(),
    amount: Yup.string().matches(/^\d+$/, "Amount must contain only digits").required("Amount is required"),
    email: Yup.string().email("Invalid email address").required("Email is required"),
    early_bird: Yup.string().when("showEarlyBird", {
      is: true,
      then: Yup.string().required("Please select an early bird option"),
    }),
    regular: Yup.string().when("showEarlyBird", {
      is: false,
      then: Yup.string().required("Please select a regular option"),
    }),
  });
  const [isLoading, setLoading] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const [save, setSave] = useState(false);
  const [showEarlyBird, setShowEarlyBird] = useState(true);
  const { email } = useParams();

  // const [transactionId, setTransactionId] = useState(null);

  const formik = useFormik({
    initialValues: {
      action: 1,
      amount: "",
      email: email || "",
      early_bird: "",
      regular: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      // const selectedBooth = showEarlyBird ? values.early_bird : values.regular;
      // const amount = selectedBooth.match(/₦(\d+,?\d*)/)[1].replace(/,/g, "");
      // setLoading(true);
      // console.log(selectedBooth);
      const boothOptions = showEarlyBird ? earlyBird : regular;
      const selectedBooth = boothOptions.find((item) => item.title === (showEarlyBird ? values.early_bird : values.regular));

      if (selectedBooth) {
        const amount = selectedBooth.title.match(/₦(\d+,?\d*)/)[1].replace(/,/g, ""); // Extracts the amount
        setLoading(true);
      }
      const formData = {
        action: values.action,
        amount: values.amount,
        email: values.email,
        booth: selectedBooth.id, // This should match the selected booth's ID
        name: selectedBooth.title,
      };
      console.log(formData);
      try {
        const response = await axios.post("https://thebabyconvention.com/wbe/api/payments.php", formData);
        console.log(response);
        if (response.data.responsecode === "200") {
          console.log(response.data);
          const paymentData = {
            amount: response.data.amount,
            id: response.data.id,
            email: response.data.email,
            payment_reference: response.data.payment_reference,
            booth: response.data,
          };

          // Save the object to localStorage as a JSON string
          localStorage.setItem("paymentData", JSON.stringify(paymentData));
          window.location.href = response.data.url;
        } else {
          setResponseMessage("Registration failed. Please try again.");
        }
        return response.data;
      } catch (error) {
        console.error("Error registering user:", error);
        setResponseMessage("An error occurred. Please try again later.");
      }
    },
  });

  useEffect(() => {
    const checkPaymentStatus = async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const transactionId = urlParams.get("reference");

      // Retrieve paymentData from localStorage
      const storedPaymentData = JSON.parse(localStorage.getItem("paymentData"));

      if (transactionId && storedPaymentData) {
        try {
          const response = await axios.post("https://thebabyconvention.com/wbe/api/payments.php", {
            action: 2,
            id: storedPaymentData.id, // Use the stored ID from paymentData
            transaction: transactionId,
            vendor_name: "", // You can add this if necessary
            email: storedPaymentData.email, // Use the stored email from paymentData
          });

          if (response.data.status === "success") {
            setResponseMessage("Payment successful!");
            setSave(true);
          } else {
            setResponseMessage("Payment failed or rejected. Please try again.");
          }
        } catch (error) {
          console.error("Error checking payment status:", error);
          setResponseMessage("An error occurred while verifying the payment.");
        }
      } else {
        setResponseMessage("Payment data is missing or invalid.");
      }
    };

    checkPaymentStatus();
  }, []); // No dependency on transactionId needed, as you retrieve it inside the effect

  console.log(responseMessage);

  useEffect(() => {
    const selectedBooth = showEarlyBird ? formik.values.early_bird : formik.values.regular;

    if (selectedBooth) {
      const extractedAmount = selectedBooth.match(/₦(\d+,?\d*)/)[1].replace(/,/g, "");
      formik.setFieldValue("amount", extractedAmount);
    }
  }, [formik.values.early_bird, formik.values.regular, showEarlyBird]);

  useEffect(() => {
    const currentDate = new Date(); //"2024-10-16"
    const cutoffDate = new Date(currentDate.getFullYear(), 9, 15); // October is month 9 (0-indexed)
    if (currentDate >= cutoffDate) {
      setShowEarlyBird(false);
    }
  }, []);
  const handleSelectedMasterClassChange = (e) => {
    formik.handleChange(e);
  };

  return (
    <>
      <div className=" w-full flex justify-between items-start">
        <div style={{ backgroundImage: ` url(${pic})`, backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundColor: "rgba(254, 135, 52) 60%" }} className="100:hidden xl:flex w-[100vh] fixed top-0 left-0  h-full">
          <div className=" w-full bg-[#FE8734]/60 h-full">
            <div className=" w-full   flex justify-end items-end h-full" style={{ backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.2) 20%, rgba(0, 0, 0, 0.7) 100%)` }}>
              <div className="p-10 sm:p-[50px] md:p-[75px] lg:p-[50px] w-full gap-[48px] flex flex-col justify-start items-start">
                <Star />
                <div className="flex-col justify-start items-center gap-6 inline-flex">
                  <h1 className="self-stretch text-white text-[32px] sm:text-[40px] md:text-[48px] lg:text-[64px] font-semibold font-playfair leading-tight">Join the Baby Convention Today!</h1>
                  <p className="self-stretch text-[#cfcfd2] text-lg font-normal font-openSans leading-[27px]">
                    Don't miss out on the ultimate event for expectant parents and families with young children! Our Baby Convention offers a wealth of resources, expert advice, product showcases, and fun activities.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className=" h-fit pb-[50px] flex flex-col justify-start items-center gap-[62px] w-full 100:pl-[40px] xl:pl-[105vh] pr-[40px]">
          <div className=" h-[100px] left-0 top-0 bg-white/40 w-full 100:px-[50px] xl:px-0  100:fixed xl:static   flex  justify-between items-center">
            <Link to="/home">
              <div className="w-fit flex justify-center items-center gap-2">
                <div>
                  <img className="w-full" src={arrow} alt="back" />
                </div>
                <div className="text-[#fe8734] 100:hidden md:block text-base font-semibold font-openSans">Back</div>
              </div>
            </Link>
            <div className=" 100:w-[60px] iphone:w-[80px] iphone:h-[54.89px]">
              <img className="w-full" src={logo} alt="The Baby Convention" />
            </div>
            <div className="w-[60px] 100:hidden md:block"></div>
          </div>
          <div className="sm:w-[450px] 100:w-full 100:mt-[100px] xl:mt-0 flex-col justify-start items-start gap-8 inline-flex">
            <h3 className="self-stretch text-[#3c3b3e] text-[32px] font-semibold font-playfair">Select Booth Type</h3>
            <form onSubmit={formik.handleSubmit} className=" w-full   pb-[10px]  flex-col justify-start items-start gap-[24px] inline-flex">
              <label className="flex-col w-full justify-start items-start gap-1.5 inline-flex text-[#4e4d51] text-sm font-semibold font-openSans" htmlFor="Email Address">
                Email
                <input
                  type="email"
                  placeholder="Email Address"
                  className="outline-[#fe8734] h-[43px] w-full pl-4 pr-3.5 py-3 bg-white rounded-[20px] shadow border border-[#aeaeb2] justify-start items-center"
                  id="email"
                  value={formik.values.email} // Set the email value from formik
                  readOnly // Make the email input read-only
                />
                {formik.touched.email && formik.errors.email ? <div className="text-red-400 text-[14px] font-openSans font-medium">{formik.errors.email}</div> : null}{" "}
              </label>
              {/* <label className="flex-col w-full justify-start items-start gap-1.5 inline-flex text-[#4e4d51] text-sm font-semibold font-openSans" htmlFor="Phone number">
                Amount
                <input
                  type="number"
                  inputMode="numeric"
                  placeholder="Amount"
                  className="outline-[#fe8734] h-[43px] w-full pl-4 pr-3.5 py-3 bg-white rounded-[20px] shadow border border-[#aeaeb2] justify-start items-center"
                  id="amount"
                  {...formik.getFieldProps("amount")}
                />
                {formik.touched.amount && formik.errors.amount ? <div className=" text-red-400 text-[14px] font-openSans font-medium">{formik.errors.amount}</div> : null}
              </label> */}
              {showEarlyBird ? (
                <label className="flex-col  w-full justify-start items-start gap-3 inline-flex text-[#4e4d51] text-sm font-semibold font-openSans" htmlFor="attend">
                  Early Bird Discounts Available from (September 1st to October 15th)
                  {earlyBird.map((item) => (
                    <label className=" flex justify-start items-center gap-[8px] text-[#6b6a70] text-sm font-normal font-openSans leading-tight" htmlFor={item.title}>
                      <input
                        type="radio"
                        value={item.title}
                        className="w-4 h-4 appearance-none checked:bg-[#fe8734] bg-white rounded-lg border border-[#cfcfd2]"
                        id={item.id}
                        name="early_bird"
                        onChange={handleSelectedMasterClassChange}
                        checked={formik.values.early_bird === item.title}
                      />
                      {item.title}
                    </label>
                  ))}
                  {formik.touched.early_bird && formik.errors.early_bird ? <div className="text-red-400 text-[14px] font-openSans font-medium">{formik.errors.early_bird}</div> : null}
                </label>
              ) : (
                <label className="flex-col  w-full justify-start items-start gap-3 inline-flex text-[#4e4d51] text-sm font-semibold font-openSans" htmlFor="attend">
                  Regular Fee Available from (October 16th to November 1st)
                  {regular.map((item) => (
                    <label className=" flex justify-start items-center gap-[8px] text-[#6b6a70] text-sm font-normal font-openSans leading-tight" htmlFor={item.title}>
                      <input
                        type="radio"
                        value={item.title}
                        className="w-4 h-4 appearance-none checked:bg-[#fe8734] bg-white rounded-lg border border-[#cfcfd2]"
                        id={item.id}
                        name="regular"
                        onChange={handleSelectedMasterClassChange}
                        checked={formik.values.regular === item.title}
                      />
                      {item.title}
                    </label>
                  ))}
                  {formik.touched.regular && formik.errors.regular ? <div className="text-red-400 text-[14px] font-openSans font-medium">{formik.errors.regular}</div> : null}
                </label>
              )}
              <button type="submit" className="w-full mt-[24px]" disabled={isLoading}>
                <div className="w-full h-12 px-8 py-2 bg-[#fe8734] rounded-[20px] justify-center text-[#f5f5f6] text-xl font-semibold font-openSans items-center inline-flex">{isLoading ? "Processing..." : "Submit"}</div>
              </button>
            </form>
          </div>
        </div>
      </div>
      {/* {responseMessage && (
        <>
          <div className=" fixed z-[50] flex justify-center items-center w-full h-full bg-black bg-opacity-40 top-0 left-0">
            <div className=" 100:w-[80%] h-fit sm:w-[500px]  px-4 py-6 bg-[#f5f5f6] rounded-[20px] flex-col justify-start items-center  inline-flex">
              <div className="w-full px-2 justify-end items-center inline-flex">
                <div onClick={() => setResponseMessage(false)} className="w-12 cursor-pointer h-12 justify-center flex  items-center bg-black/30 rounded-[100px] backdrop-blur-[31.63px]  ">
                  <img className=" " src={cancel} alt="Cancel" />
                </div>
              </div>

              <div className=" flex-col  w-full  justify-center items-center gap-[24px] inline-flex">
                <div className="w-12 h-12 flex justify-center items-center bg-[#ade4ac] rounded-[28px] border-8 border-[#e7f6ec]">
                  <img src={tick} alt="tick" />
                </div>
                <div className=" w-full px-2 flex-col justify-center items-center gap-2 inline-flex">
                  <h3 className="text-[#3c3b3e] text-center text-xl font-semibold font-openSans">{responseMessage}</h3>
                  <p className=" text-center text-[#6b6a70] text-sm font-normal font-openSans">{responseMessage}</p>
                </div>
                <div className=" w-full flex justify-center items-center">
                  <button>
                    <div
                      onClick={() => {
                        setResponseMessage(false);
                        setLoading(false);
                      }}
                      className="w-[250px] h-12 px-8 py-2 bg-[#fe8734] rounded-[20px] justify-center text-[#f5f5f6] text-xl font-semibold font-openSans items-center inline-flex"
                    >
                      Ok
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      )} */}
    </>
  );
}

export default Payment;
