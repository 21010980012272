import { useEffect, useState } from "react";
import love from "../../../Images/svg/love.svg";
import calendar from "../../../Images/svg/calendar.svg";
import event1 from "../../../Images/photos/event1.png";
import event2 from "../../../Images/photos/event2.jpeg";
import event3 from "../../../Images/photos/event3.png";
import { Link } from "react-router-dom";

function UpcomingEvent() {
  const calculateDaysLeft = () => {
    const difference = +new Date("2024-11-16") - +new Date();
    return Math.floor(difference / (1000 * 60 * 60 * 24));
  };
  const [daysLeft, setDaysLeft] = useState(calculateDaysLeft());
  const box = [
    {
      id: 1,
      picture: event1,
      time: daysLeft > 0 ? `${daysLeft} Days to go` : "Event has passed!",
      title: "Doors Open and Music",
      description:
        "Join us for a vibrant baby shower extravaganza! Explore a diverse selection of vendors offering baby essentials, clothing, toys, and more—all set to lively music. It's the perfect place to find everything you need for your little one.",
      venue: "La Madison Place Oniru Lekki",
      actualTime: "9am to 10 am",
      date: "Saturday, November 16th, 2024",
      speaker: "N/A",
    },
    {
      id: 2,
      picture: event2,
      time: daysLeft > 0 ? `${daysLeft} Days to go` : "Event has passed!",
      title: "Opening Remarks by Dr. Renner: The Baby Convention and the Power of Community",
      description: "Gain valuable insights into how community and collaboration play a crucial role in promoting children's health and well-being.",
      venue: "La Madison Place Oniru Lekki",
      actualTime: "10 am to 10.15 am ",
      date: "Saturday, November 16th, 2024",
      speaker: "Dr. Renner",
    },
    {
      id: 3,
      picture: event3,
      time: daysLeft > 0 ? `${daysLeft} Days to go` : "Event has passed!",
      title: "Key Note ",
      description: "Discover insights on the impact of community and collaboration in promoting children’s health and well-being.",
      venue: "La Madison Place Oniru Lekki",
      actualTime: "10.20 am to 11.00 am",
      date: "Saturday, November 16th, 2024",
      speaker: "Nimi Akinkugbe or Dr. Zainab Shinkafi BAgudu",
    },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);
  const slideDelay = 3000; // Time in milliseconds
  console.log(currentIndex);
  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex === box.length - 1 ? 0 : prevIndex + 1));
    }, slideDelay);

    // Cleanup timer on component unmount
    return () => clearInterval(timer);
  }, []);
  return (
    <>
      <div className="  w-full h-fit   py-[96px] 100:px-[32px] md:px-[64px] lg:px-[32px] flex-col flex justify-center items-center">
        <div className="gap-[48px] 100:w-full lg:w-[90vh] xl:w-[90%] flex-col flex justify-center items-center">
          <div className="  w-full justify-between items-start flex">
            <div className=" flex flex-col justify-start items-start gap-[10px]">
              <div className="half-bg-text">
                <h3 className="text-[#3c3b3e] relative text-[32px] font-semibold font-playfair">Don't Miss Our Upcoming Events</h3>
              </div>
              <p className="text-[#6b6a70] text-lg font-normal font-openSans">Join Our Baby Shows, Workshops, and Expos</p>
            </div>
            <div>
              <img src={love} alt="" />
            </div>
          </div>

          <div className=" flex h-fit xl:flex-nowrap flex-1  100:flex-wrap  justify-center items-start w-full lg:gap-8 100:gap-10 ">
            {box.map((item) => (
              <>
                {/* <div key={item.id} className=" h-fit p-[10px] 100:w-[320px] 2xl:flex-grow sm:w-[420px]"> */}
                <div key={item.id} className={`100:w-full    sm:w-full md:w-[45%]  flex-grow flex-col items-center  justify-center lg:w-[420px] h-fit rounded-[24px] flex  `}>
                  <div style={{ backgroundImage: `url(${item.picture})`, backgroundSize: "cover", backgroundPosition: "center" }} className="w-full 100:h-[250px] md:h-[300px] rounded-[20px]">
                    <h5 className="h-6 top-[20px] mt-[10px] ml-[10px] px-2 py-1 bg-[#fff6ed] rounded-[20px] border border-[#ffb472] justify-end items-center gap-1 inline-flex text-[#6b6a70] text-xs font-semibold font-openSans">{item.time}</h5>
                  </div>
                  <div className=" 2xl:w-[90%] 100:w-[87%] mt-[-64px] h-fit p-6 bg-[#fff6ed] rounded-[20px] flex-col justify-start items-start gap-4 inline-flex">
                    <div className=" flex-col text-left justify-start items-start gap-[10px] inline-flex">
                      <h3 className="text-[#3c3b3e] text-xl font-bold font-openSans">{item.title}</h3>
                      <p className="text-[#6b6a70] text-sm font-normal font-playfair italic">{item.description}</p>
                      <div className="bg-[#FAE9F2] w-fit h-fit rounded-[20px] gap-[4px] px-[8px] py-[4px] flex justify-center items-center">
                        <img src={calendar} alt="calenadr" />

                        <p className="text-[#e05493] text-[12px] font-semibold font-openSans">{item.date}</p>
                      </div>
                      <h4 className="text-[#6b6a70] text-xs font-medium font-openSans">
                        Time: <span className="text-[#3c3b3e] font-semibold">{item.actualTime}</span>
                      </h4>{" "}
                      <h4 className="text-[#6b6a70] flex justify-start items-start gap-[4px] text-xs font-medium font-openSans">
                        Speaker: <span className="text-[#3c3b3e] font-semibold">{item.speaker}</span>
                      </h4>
                      <h4 className="text-[#6b6a70] text-xs font-medium font-openSans">
                        Venue: <span className="text-[#3c3b3e] font-semibold">{item.venue}</span>
                      </h4>{" "}
                    </div>
                    <div className=" w-full justify-end items-center inline-flex">
                      <Link onClick={() => window.scrollTo(0, 0)} to="/options">
                        <div className="w-[77px] h-[23px] px-8 py-1 bg-[#fe8734] rounded-[20px] justify-center items-center inline-flex text-[#f5f5f6] text-xs font-medium font-openSans">Register</div>
                      </Link>
                    </div>
                  </div>
                </div>
              </>
            ))}
          </div>
          {/* </div> */}
        </div>
      </div>
    </>
  );
}

export default UpcomingEvent;
