import axios from "axios";
import { useEffect, useState } from "react";
import SideBar from "./SideBar";
import { ReactComponent as Search } from "../Images/svg/search.svg";

function Sponsors() {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  useEffect(() => {
    const fetchData = async () => {
      const dataSend = {
        model: "sponsors",
      };
      try {
        const res = await axios.post("https://thebabyconvention.com/wbe/api/get_all.php", dataSend, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        console.log("get vendors ", res, dataSend);
        setData(res.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [page]);
  return (
    <>
      <div className="bg-[#f5f5f6] h-fit w-full flex justify-between items-start">
        <SideBar />
        <div className=" 100:pt-[100px] xl:pt-[24px]  w-full h-fit  py-8 flex flex-col 100:px-[40px] xl:pl-[38vh] justify-start items-start gap-8">
          <div className="w-full flex-col justify-start items-start gap-1 inline-flex">
            <h2 className="self-stretch text-[#3c3b3e] text-[32px] font-semibold font-openSans">Sponsor Management</h2>
            <p className="lg:w-[705px] 100:w-full text-[#6b6a70] text-base font-normal font-openSans">This section lets admins manage Sponsors accounts, search Sponsors, view profiles, and update information.</p>
          </div>

          {/* TABLE */}
          <div className=" w-full gap-[8px] flex-col justify-start items-start flex">
            <h5 className="self-stretch text-[#6b6a70] text-xs font-semibold font-openSans  ">Sponsors</h5>
            <div className="h-fit w-full whitespace-nowrap  100:overflow-x-scroll bg-white rounded-lg shadow border border-[#cfcfd2]  flex-col justify-center items-start gap-[24px] inline-flex">
              {" "}
              <div className=" h-[82px] flex justify-start items-center w-full p-[20px]">
                <div className="self-stretch 100:flex h-[42px] md:hidden gap-[12px]  w-full   px-3.5 py-2.5 bg-white rounded-[20px] shadow border border-[#aeaeb2] justify-start items-center  inline-flex text-[#6c757d] text-base font-normal leading-normal">
                  <Search /> <input className="rounded-lg pr-3.5 bg-transparent border-none outline-none w-full h-full" placeholder="Search" />
                </div>

                <div className="self-stretch gap-[12px] h-[42px] 100:hidden md:flex w-[400px]   px-3.5 py-2.5 bg-white rounded-[20px] shadow border border-[#aeaeb2] justify-start items-center  inline-flex text-[#6c757d] text-base font-normal leading-normal">
                  <Search /> <input className="rounded-lg pr-3.5 bg-transparent border-none outline-none w-full h-full" placeholder="Search" />
                </div>
              </div>
              <div className=" self-stretch w-full border-t border-t-[#CDD1D4] flex justify-between items-center ">
                {/* ROW 1*/}
                <div className=" w-full flex flex-col justify-start items-start">
                  <div className="h-11 w-full px-6 py-3 bg-white justify-start items-center gap-3 inline-flex text-[#6b6a70] text-xs font-semibold font-openSans">Sponsor ID</div>
                  {data?.map((item, index) => (
                    <div className={`h-[72px] w-full px-6 py-4 ${index % 2 === 0 ? "bg-[#f5f6f6]" : "bg-white"}  justify-start items-center gap-3 inline-flex text-[#4e4d51] text-sm font-normal font-openSans `}>{item.id}</div>
                  ))}
                </div>
                {/* ROW 2*/}
                <div className=" w-full flex flex-col justify-start items-start">
                  <div className="h-11 w-full px-6 py-3 bg-white justify-start items-center gap-3 inline-flex text-[#6b6a70] text-xs font-semibold font-openSans">Name</div>
                  {data?.map((item, index) => (
                    <div className={`h-[72px] w-full px-6 py-4 ${index % 2 === 0 ? "bg-[#f5f6f6]" : "bg-white"}  justify-start items-center gap-3 inline-flex text-[#4e4d51] text-sm font-normal font-openSans `}>{item.company_name}</div>
                  ))}{" "}
                </div>
                {/* ROW 3*/}
                <div className=" w-full flex flex-col justify-start items-start">
                  <div className="h-11 w-full px-6 py-3 bg-white justify-start items-center gap-3 inline-flex text-[#6b6a70] text-xs font-semibold font-openSans">Email</div>
                  {data?.map((item, index) => (
                    <div className={`h-[72px] w-full px-6 py-4 ${index % 2 === 0 ? "bg-[#f5f6f6]" : "bg-white"}  justify-start items-center gap-3 inline-flex text-[#4e4d51] text-sm font-normal font-openSans `}>{item.official_email}</div>
                  ))}{" "}
                </div>

                {/* ROW 4*/}
                <div className=" w-full flex flex-col justify-start items-start">
                  <div className="h-11 w-full px-6 py-3 bg-white justify-start items-center gap-3 inline-flex text-[#6b6a70] text-xs font-semibold font-openSans">Category</div>
                  {data?.map((item, index) => (
                    <div className={`h-[72px] w-full px-6 py-4 ${index % 2 === 0 ? "bg-[#f5f6f6]" : "bg-white"}  justify-start items-center gap-3 inline-flex text-[#4e4d51] text-sm font-normal font-openSans `}>{item.organization_category}</div>
                  ))}
                </div>
                {/* ROW 6*/}
                <div className=" w-full flex flex-col justify-start items-start">
                  <div className="h-11 w-full px-6 py-3 bg-white justify-start items-center gap-3 inline-flex text-[#6b6a70] text-xs font-semibold font-openSans">Sponsorship Type</div>
                  {data?.map((item, index) => (
                    <div className={`h-[72px] w-full px-6 py-4 ${index % 2 === 0 ? "bg-[#f5f6f6]" : "bg-white"}  justify-start items-center gap-3 inline-flex text-[#4e4d51] text-sm font-normal font-openSans `}>{item.sponsorship_category}</div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Sponsors;
