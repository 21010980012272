import { Link } from "react-router-dom";
import thumb from "../../../Images/svg/thumb.svg";
import resource1 from "../../../Images/photos/resources1.png";
import resource2 from "../../../Images/photos/resources2.png";
import resource3 from "../../../Images/photos/resources3.png";

function Resources() {
  const box = [
    {
      id: 1,
      picture: resource1,
      title: "Top 10 Pregnancy Myths Debunked",
      description: "Explore common pregnancy myths and the facts behind them. This article covers everything from old wives' tales to modern misconceptions, providing evidence-based information to ease your mind during pregnancy.",
    },
    {
      id: 2,
      picture: resource2,
      title: "How to Soothe a Crying Baby",
      description: "Discover effective methods to calm a crying baby. From understanding why babies cry to practical techniques for soothing, this article offers comprehensive guidance for new parents.",
    },
    {
      id: 3,
      picture: resource3,
      title: "Potty Training 101: A Complete Guide",
      description:
        "Get a comprehensive guide on how to potty train your toddler. Covering signs of readiness, step-by-step instructions, and troubleshooting tips, this article will help make potty training a smoother process for both you and your child.",
    },
  ];
  return (
    <>
      <div className=" w-full h-fit gap-[60px] py-[96px] 100:px-[32px] md:px-[64px] flex-col flex justify-center items-center">
        <div className=" 100:w-full sm:w-[90%] xl:w-[1119px] flex-col justify-start items-start gap-12 inline-flex">
          <div className=" w-full justify-between items-start inline-flex">
            <div className=" flex-col justify-start items-start gap-2.5 inline-flex">
              <h3 className="text-[#3c3b3e] text-[32px] font-semibold font-playfair">Expert Advice and Resources</h3>
              <p className="text-[#6b6a70] text-lg font-normal font-openSans">Get Tips and Guidance from Parenting Experts</p>
            </div>
            <div>
              <img src={thumb} alt="thumb" />
            </div>
          </div>
          <div className="w-full h-fit flex-col justify-center items-start gap-8 inline-flex">
            {box.map((item) => (
              <div key={item.id} className="w-full 100:h-fit lg:h-[360px] p-2.5 100:flex-col lg:flex-row 100:justify-center lg:justify-start items-center flex">
                <div className="  w-full">
                  <img alt="" className="grow shrink  w-full basis-0 self-stretch rounded-[20px]" src={item.picture} />
                </div>
                <div className="lg:h-80 100:h-fit 100:w-[85%] lg:w-full p-6 bg-[#fff6ed] 100:mt-[-64px] lg:mt-0 lg:ml-[-84px] z-10 rounded-[20px] flex-col justify-center items-center gap-4 inline-flex">
                  <div className="md:w-[410px] 100:w-full flex-col justify-start items-center gap-2 inline-flex">
                    <div className="grow shrink basis-0 text-center text-[#3c3b3e] text-xl font-bold font-openSans">{item.title}</div>

                    <div className=" text-center text-[#6b6a70] text-sm font-normal font-openSans">{item.description} </div>
                  </div>
                  <Link onClick={() => window.scrollTo(0, 0)} to="/expert-advice">
                    <div className="text-[#6b6a70] text-base font-bold font-openSans underline">Learn More</div>
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default Resources;
