import { useState } from "react";
import logo from "../Images/photos/ThebabyConventionColorLogo.png";
import arrow from "../Images/svg/arrowleft.svg";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import cancel from "../Images/svg/Cancel.svg";
import { ReactComponent as Pending } from "../Images/svg/pending.svg";
import { ReactComponent as Reject } from "../Images/svg/reject.svg";
import { ReactComponent as Completed } from "../Images/svg/approve.svg";

function Reciept() {
  // const validationSchema = Yup.object({
  //   email: Yup.string().email("Invalid email address").required("Email is required"),
  // });
  // const [isLoading, setLoading] = useState(false);
  // const [responseMessage, setResponseMessage] = useState("");
  // const [save, setSave] = useState(false);
  // const [data, setData] = useState([]);

  // const formik = useFormik({
  //   initialValues: {
  //     email: "",
  //   },
  //   validationSchema,
  //   onSubmit: async (values) => {
  //     setLoading(true);
  //     const formData = {
  //       email: values.email,
  //     };
  //     console.log(formData);
  //     try {
  //       const response = await axios.post("https://thebabyconvention.com/wbe/api/get_details.php", formData);
  //       console.log(response);
  //       if (response.data.responsecode === "200") {
  //         setSave(true);
  //         setResponseMessage(response.data.responsemessage);
  //         setData(response.data.data); // Update data here
  //       } else {
  //         setResponseMessage("Registration failed. Please try again.");
  //       }
  //       return response.data;
  //     } catch (error) {
  //       console.error("Error registering user:", error);
  //       setResponseMessage("An error occurred. Please try again later.");
  //     }
  //   },
  // });
  const validationSchema = Yup.object({
    email: Yup.string().email("Invalid email address").required("Email is required"),
  });
  const [isLoading, setLoading] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const [save, setSave] = useState(false);
  const [data, setData] = useState(null); // Initialize as null
  const [isSelected, setIsSelected] = useState(null);

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      const formData = {
        email: values.email,
      };
      console.log(formData);
      try {
        const response = await axios.post("https://thebabyconvention.com/wbe/api/get_details.php", formData);
        console.log(response);
        if (response.data.responsecode === "200" && response.data.data) {
          setSave(true);
          setResponseMessage(response.data.responsemessage);
          setData(response.data.data); // Update data here
        } else {
          setResponseMessage("Participant not found.");
          setData(null); // Set data to null if not found
        }
      } catch (error) {
        console.error("Error fetching participant data:", error);
        setResponseMessage("An error occurred. Please try again later.");
        setData(null);
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <>
      <div className=" w-full flex flex-col justify-start items-center px-[10px] py-[40px] ">
        <div className=" h-[100px] bg-white/40 w-full top-0 left-[40px] 100:pr-[70px] right-[40px] flex fixed justify-between items-center">
          <Link to="/admin/user-management">
            <div className="w-fit flex justify-center items-center gap-2">
              <div>
                <img className="w-full" src={arrow} alt="back" />
              </div>
              <div className="text-[#fe8734] 100:hidden md:block text-base font-semibold font-openSans">Back</div>
            </div>
          </Link>
          <div className=" 100:w-[60px] iphone:w-[80px] iphone:h-[54.89px]">
            <img className="w-full" src={logo} alt="The Baby Convention" />
          </div>
          <div className="w-[120px] 100:hidden md:block"></div>
        </div>
        <div className="h-fit 100:w-[85%]  sm:w-[498px] mt-[130px] p-6 bg-[#fff6ed] rounded-3xl shadow flex-col justify-start items-start gap-2.5 inline-flex">
          <div className="sm:w-[450px] 100:w-full 100:mt-[100px] xl:mt-0 flex-col justify-start items-start gap-8 inline-flex">
            <div className=" flex-col justify-start items-center gap-2 inline-flex">
              <h2 className="self-stretch text-center text-[#3c3b3e] text-[32px] font-semibold font-playfair">Details & Attendance Card</h2>
              <p className=" w-[90%] text-center text-[#6b6a70] text-sm font-normal font-openSans">
                View and print your attendance card or manage other event-related processes. The details displayed below have been retrieved based on the provided email address.{" "}
              </p>
            </div>{" "}
            <form onSubmit={formik.handleSubmit} className=" w-full   pb-[10px]  flex-col justify-start items-start gap-[24px] inline-flex">
              <label className="flex-col w-full justify-start items-start gap-1.5 inline-flex text-[#4e4d51] text-sm font-semibold font-openSans" htmlFor="Email Address">
                User Email
                <input
                  type="email"
                  placeholder="Enter User Email Address"
                  className="outline-[#fe8734] h-[43px] w-full pl-4 pr-3.5 py-3 bg-white rounded-[20px] shadow border border-[#aeaeb2] justify-start items-center"
                  id="email"
                  {...formik.getFieldProps("email")}
                />
                {formik.touched.email && formik.errors.email ? <div className=" text-red-400 text-[14px] font-openSans font-medium">{formik.errors.email}</div> : null}
              </label>
              <button type="submit" className="w-full mt-[24px]">
                <div className="w-full h-12 px-8 py-2 bg-[#fe8734] rounded-[20px] justify-center text-[#f5f5f6] text-xl font-semibold font-openSans items-center inline-flex">{isLoading ? "Submitting..." : " Submit"}</div>
              </button>
            </form>
          </div>
        </div>
      </div>
      {save && (
        <>
          <div className=" fixed z-[50] flex justify-center items-center w-full h-full bg-black bg-opacity-40 top-0 left-0">
            <div className=" 100:w-[80%] h-fit sm:w-[500px]   p-2.5 bg-[#f5f5f6]  rounded-[20px] flex-col justify-start items-center  inline-flex">
              <div className="h-[400px]  w-full p-6 bg-[#f0faff] rounded-[20px] flex-col justify-start items-start gap-8 inline-flex">
                <div className="w-full justify-between items-center inline-flex">
                  <h2 className="self-stretch text-center text-[#3c3b3e] text-[20px] font-semibold font-playfair">Vendor Details</h2>

                  <div
                    onClick={() => {
                      setSave(false);
                      setLoading(false);
                    }}
                    className="w-12 cursor-pointer h-12 justify-center flex  items-center bg-black/30 rounded-[100px] backdrop-blur-[31.63px]  "
                  >
                    <img className=" " src={cancel} alt="Cancel" />
                  </div>
                </div>
                {data ? (
                  <div className="w-full h-fit overflow-y-scroll flex-col justify-start items-start gap-4 inline-flex">
                    <div className="self-stretch  flex-col justify-start items-start gap-2 flex">
                      <h4 className="text-[#3c3b3e] text-2xl font-semibold font-openSans">{data?.vendor_name}</h4>
                    </div>
                    <div className="w-full h-px bg-[#e6e6e7]" />
                    <div className=" flex-col justify-start items-start gap-2.5 inline-flex">
                      <h4 className="text-[#6b6a70] w-full text-xs font-semibold font-openSans ">
                        Vendor Category: <span className="text-[#86858b] text-xs font-normal font-openSans ">{data?.vendor_category}</span>
                      </h4>
                      <div className="text-[#6b6a70] text-xs font-semibold font-openSans ">
                        Booth Type: <span className="text-[#86858b] text-xs font-normal font-openSans ">{data?.booth_type}</span>
                      </div>
                      <div className="text-[#6b6a70] text-xs font-semibold font-openSans ">
                        Instagram: <span className="text-[#86858b] text-xs font-normal font-openSans ">{data?.instagram_handle}</span>
                      </div>
                    </div>
                    <div className="w-full h-px bg-[#e6e6e7]" />
                    <div className=" flex-col justify-start items-start gap-2.5 inline-flex">
                      <h4 className="text-[#6b6a70] w-full text-xs font-semibold font-openSans ">
                        Vendor Id: <span className="text-[#86858b] text-xs font-normal font-openSans ">{data?.id}</span>
                      </h4>
                      <h4 className="text-[#6b6a70] w-full text-xs font-semibold font-openSans ">
                        Vendor Email: <span className="text-[#86858b] text-xs font-normal font-openSans ">{data?.official_email}</span>
                      </h4>
                      <h4 className="text-[#6b6a70] w-full text-xs font-semibold font-openSans ">
                        Phone Number: <span className="text-[#86858b] text-xs font-normal font-openSans ">{data?.official_phone_number}</span>
                      </h4>
                    </div>
                    <div className="w-full h-px bg-[#e6e6e7]" />
                    <div className=" flex-col justify-start items-start gap-2.5 inline-flex">
                      <h4 className="text-[#6b6a70] w-full text-xs font-semibold font-openSans ">
                        Registered Date: <span className="text-[#86858b] text-xs font-normal font-openSans ">{data?.date_created}</span>
                      </h4>
                      <h4 className="text-[#6b6a70] w-full text-xs font-semibold font-openSans ">
                        Registered Approved: <span className="text-[#86858b] text-xs font-normal font-openSans ">{data?.date_approved}</span>
                      </h4>
                      <h4 className="text-[#6b6a70] w-full text-xs font-semibold font-openSans ">
                        Approver: <span className="text-[#86858b] text-xs font-normal font-openSans ">{data?.approved_by}</span>
                      </h4>
                    </div>
                    <div className="w-full h-px bg-[#e6e6e7]" />
                    {/* <div className=" flex-col justify-start items-start gap-2.5 inline-flex">
                      <h4 className="text-[#6b6a70] w-full text-xs font-semibold font-openSans ">
                        Payment Reference Key: <span className="text-[#86858b] text-xs font-normal font-openSans ">{data.payment_details[0].payment_reference}</span>
                      </h4>
                      <h4 className="text-[#6b6a70] w-full text-xs font-semibold font-openSans ">
                        Booth Amount: <span className="text-[#86858b] text-xs font-normal font-openSans ">{data?.payment_details && data.payment_details.length > 0 ? data.payment_details[0].amount : "No amount available"}</span>
                      </h4>
                      <h4 className="text-[#6b6a70] w-full text-xs font-semibold font-openSans ">
                        Payment Status : <span className="text-[#86858b] text-xs font-normal font-openSans ">{data?.payment_details && data.payment_details.length > 0 ? data.payment_details[0].status : "No payment status available"} </span>
                      </h4>
                      <div className="text-[#6b6a70] flex items-center justify-start gap-[6px] text-xs font-semibold font-openSans ">
                        Approval Status:{" "}
                        <div className={`pl-1.5 pr-2 py-0.5 mix-blend-multiply rounded-2xl justify-center items-center gap-1 flex ${data?.status === "approved" ? "bg-[#ebfdf2]" : "bg-red-100"}`}>
                          {data?.status === "approved" ? <Completed /> : <Reject />}
                          <div className={`text-center ${data?.status === "approved" ? "text-[#1f7634]" : data?.status === "REJECT" ? "text-red-500" : "text-[#bb6902]"} text-xs font-medium leading-[18px]`}>{data?.status}</div>
                        </div>
                      </div>
                    </div> */}
                    <div className=" flex-col justify-start items-start gap-2.5 inline-flex">
                      <h4 className="text-[#6b6a70] w-full text-xs font-semibold font-openSans ">
                        Payment Reference Key:{" "}
                        <span className="text-[#86858b] text-xs font-normal font-openSans ">{data?.payment_details && data.payment_details.length > 0 ? data.payment_details[0].payment_reference : "No payment reference available"}</span>
                      </h4>
                      <h4 className="text-[#6b6a70] w-full text-xs font-semibold font-openSans ">
                        Booth Amount: <span className="text-[#86858b] text-xs font-normal font-openSans ">{data?.payment_details && data.payment_details.length > 0 ? data.payment_details[0].amount : "No amount available"}</span>
                      </h4>
                      <h4 className="text-[#6b6a70] w-full text-xs font-semibold font-openSans ">
                        Payment Status: <span className="text-[#86858b] text-xs font-normal font-openSans ">{data?.payment_details && data.payment_details.length > 0 ? data.payment_details[0].status : "No payment status available"}</span>
                      </h4>
                    </div>
                  </div>
                ) : (
                  <div className="flex flex-col justify-center items-center gap-4">
                    {/* <NotFoundIcon className="w-16 h-16" /> */}
                    <p className="text-[#6b6a70] text-center">Participant not found or no details available.</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default Reciept;
