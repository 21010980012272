import SideBar from "./SideBar";
import { ReactComponent as Search } from "../Images/svg/search.svg";
import { ReactComponent as Pending } from "../Images/svg/pending.svg";
import { ReactComponent as Reject } from "../Images/svg/reject.svg";
import { ReactComponent as Completed } from "../Images/svg/approve.svg";
import cancel from "../Images/svg/Cancel.svg";
import tick from "../Images/svg/ticked.svg";
import filter from "../Images/svg/filter.svg";
import { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
// import PrivacyParticipant from "../PrivacyParticipant";
import moment from "moment";
import axios from "axios";
import { useParams } from "react-router-dom";

function Vendor() {
  const validationSchema = Yup.object({
    vendor_id: Yup.string().matches(/^\d+$/, "Vendor ID must contain only digits").required("Vendor ID is required"),
    vendor_name: Yup.string().required("Last name is required"),
    official_email: Yup.string().email("Invalid email address").required("Email is required"),
    approved_by: Yup.string().required("Approver name is required"),
  });
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [approve, setApprove] = useState(false);
  const [vendorDetails, setVendorDetails] = useState(false);
  const [isSelected, setIsSelected] = useState(null);
  const [save, setSave] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const [filtered, setFiltered] = useState(false);
  // const [filterStatus, setFilterStatus] = useState("pending");
  const [filterStatus, setFilterStatus] = useState("all");
  // const { id, user, booth } = useParams();
  // const boothDataString = localStorage.getItem("paymentData");
  // const boothData = JSON.parse(boothDataString); // Convert JSON string to object

  // // Access the 'booth' object
  // const booth = boothData.booth; // This is the first object in your data

  // console.log(booth); // Print the 'booth' object to the console

  // Access specific properties
  // const boothId = booth.id;
  // const formik = useFormik({
  //   initialValues: {
  //     vendor_id: "",
  //     official_email: "",
  //     vendor_name: "",
  //     action: "", // This will indicate "approved" or "rejected"
  //   },
  //   validationSchema,
  //   onSubmit: async (values) => {
  //     setLoading(true);

  //     // Extract the values from Formik's form
  //     const { vendor_id, official_email, vendor_name, action } = values;

  //     if (!vendor_id) {
  //       alert("Vendor ID is missing!");
  //       setLoading(false);
  //       return;
  //     }

  //     // Prepare the payload for API
  //     const payload = {
  //       id: vendor_id,
  //       official_email,
  //       vendor_name,
  //       action, // "approved" or "rejected" from the form
  //     };

  //     try {
  //       // Send the request to the single API
  //       const response = await fetch("https://thebabyconvention.com/wbe/api/approve_vendor.php", {
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //         body: JSON.stringify(payload),
  //       });

  //       setLoading(false);

  //       if (!response.ok) {
  //         throw new Error(`HTTP error! Status: ${response.status}`);
  //       }

  //       const result = await response.json();

  //       if (result.responsecode === "200") {
  //         alert(result.responsemessage);
  //         setVendorDetails(false);
  //         window.location.reload(); // Refresh the page after successful action
  //       } else {
  //         alert(`Error: ${result.responsemessage}`);
  //       }
  //     } catch (error) {
  //       console.error("Error:", error);
  //       setLoading(false);
  //       alert("An error occurred while processing your request.");
  //     }
  //   },
  // });

  // Form submit function will call handleVendorApprove or handleVendorReject based on the action value

  // const formik = useFormik({
  //   initialValues: {
  //     vendor_id: "",
  //     // official_email: "",
  //     // vendor_name: "",
  //     // approved_by: "",
  //   },
  //   validationSchema,
  //   onSubmit: async (values) => {
  //     setLoading(true);
  //     // const formData = {
  //     //   vendor_id: values.vendor_id,
  //     //   // official_email: values.official_email,
  //     //   // vendor_name: values.vendor_name,
  //     // };
  //     const { vendor_id, official_email, vendor_name, action } = values;

  //     if (!vendor_id) {
  //       alert("Vendor ID is missing!");
  //       setLoading(false);
  //       return;
  //     }

  //     // Prepare the payload for API
  //     const payload = {
  //       id: vendor_id,
  //       official_email,
  //       vendor_name,
  //       action, // "approved" or "rejected" from the form
  //     };

  //     try {
  //       // const apiURL = action === "approved";
  //       // const res = await fetch("https://thebabyconvention.com/wbe/api/approve_vendor.php", {
  //       //   method: "POST",
  //       //   headers: {
  //       //     "Content-Type": "application/json",
  //       //   },
  //       //   body: JSON.stringify(formData),
  //       // });
  //       // console.log(formData);
  //       const response = await fetch("https://thebabyconvention.com/wbe/api/approve_vendor.php", {
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //         body: JSON.stringify(payload),
  //       });

  //       setLoading(false);

  //       if (!response.ok) {
  //         throw new Error(`HTTP error! Status: ${response.status}`);
  //       }

  //       const result = await response.json();

  //       if (res.ok) {
  //         setSave(true);
  //         setApprove(false);
  //         // setShowMasterClassOptions(async (values) => {
  //         //   await new Promise((r) => setTimeout(r, 500));
  //         //   alert(JSON.stringify(values, null, 2));
  //         // });
  //         const responseData = await res.json();
  //         setResponseMessage(responseData.responsemessage || "Registration successful!");
  //       } else {
  //         setResponseMessage("Registration failed. Please try again.");
  //       }
  //     } catch (error) {
  //       setLoading(false);
  //       setResponseMessage("An error occurred. Please try again later.");
  //     }
  //   },
  // });

  // GET ALL VENDOR
  useEffect(() => {
    const fetchData = async () => {
      const dataSend = {
        model: "vendors",
      };
      try {
        const res = await axios.post("https://thebabyconvention.com/wbe/api/get_all.php", dataSend, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        console.log("get vendors ", res, dataSend);
        setData(res.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [page]);

  const handleVendorApprove = async (id, official_email, vendor_name, action = null) => {
    if (!id) {
      alert("Vendor ID is missing!");
      return;
    }
    const payload = { id, official_email, vendor_name };

    if (action === "approved") {
      payload.action = "approved";
    }

    try {
      const response = await fetch("https://thebabyconvention.com/wbe/api/approve_vendor.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const text = await response.text();
      const result = text ? JSON.parse(text) : {};

      if (result.responsecode === "200") {
        alert(result.responsemessage);
        setVendorDetails(false);
        window.location.reload();
      } else {
        alert(`Error: ${result.responsemessage}`);
      }
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred while processing your request.");
    }
  };
  const handleVendorReject = async (id, official_email, action = null) => {
    if (!id) {
      alert("Vendor ID is missing!");
      return;
    }
    const payload = { id, official_email };

    if (action === "rejected") {
      payload.action = "rejected";
    }

    try {
      const response = await fetch("https://thebabyconvention.com/wbe/api/approve_vendor.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const text = await response.text();
      const result = text ? JSON.parse(text) : {};

      if (result.responsecode === "200") {
        alert(result.responsemessage);
        setVendorDetails(false);
        window.location.reload();
      } else {
        alert(`Error: ${result.responsemessage}`);
      }
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred while processing your request.");
    }
  };

  return (
    <>
      <div className="bg-[#f5f5f6] h-fit w-full flex justify-between items-start">
        <SideBar />
        <div className=" 100:pt-[100px] xl:pt-[24px]  w-full h-fit  py-8 flex flex-col 100:px-[40px] xl:pl-[38vh] justify-start items-start gap-8">
          <div className=" w-full flex justify-start items-start lg:flex-row 100:gap-[16px] lg:gap-0 100:flex-col">
            <div className="w-full flex-col justify-start items-start gap-1 inline-flex">
              <h2 className="self-stretch text-[#3c3b3e] text-[32px] font-semibold font-openSans">Vendor Management</h2>
              <p className="lg:w-[705px] 100:w-full text-[#6b6a70] text-base font-normal font-openSans">This section lets admins manage vendors accounts, search vendors, view profiles, and update information.</p>
            </div>
            {/* <div className=" w-full flex justify-end">
              <button onClick={() => setApprove(true)} className="w-fit mt-[24px]">
                <div className="w-full  px-[20px] py-2 bg-[#fe8734] whitespace-nowrap rounded-[20px] justify-center text-[#f5f5f6] text-[16px] font-semibold font-openSans items-center inline-flex">Approve Vendor</div>
              </button>
            </div> */}
          </div>
          {/* TABLE 1 */}
          <div className=" w-full gap-[8px] flex-col justify-start items-start flex">
            <h5 className="self-stretch text-[#6b6a70] text-xs font-semibold font-openSans">{filterStatus.charAt(0).toUpperCase() + filterStatus.slice(1)} Vendors</h5>
            <div className="h-fit w-full whitespace-nowrap  100:overflow-x-scroll bg-white rounded-lg shadow border border-[#cfcfd2]  flex-col justify-center items-start gap-[24px] inline-flex">
              {" "}
              <div className=" h-[82px] 100:gap-[24px] md:ga flex justify-between items-center w-full p-[20px]">
                <div className="self-stretch 100:flex h-[42px] md:hidden gap-[12px]  w-full   px-3.5 py-2.5 bg-white rounded-[20px] shadow border border-[#aeaeb2] justify-start items-center  inline-flex text-[#6c757d] text-base font-normal leading-normal">
                  <Search /> <input className="rounded-lg pr-3.5 bg-transparent border-none outline-none w-full h-full" placeholder="Search" />
                </div>

                <div className="self-stretch gap-[12px] h-[42px] 100:hidden md:flex w-[400px]   px-3.5 py-2.5 bg-white rounded-[20px] shadow border border-[#aeaeb2] justify-start items-center  inline-flex text-[#6c757d] text-base font-normal leading-normal">
                  <Search /> <input className="rounded-lg pr-3.5 bg-transparent border-none outline-none w-full h-full" placeholder="Search" />
                </div>
                <div
                  onClick={() => setFiltered(!filtered)}
                  className=" w-fit gap-[8px] cursor-pointer   px-3.5 py-2.5 bg-white rounded-[20px] shadow border border-[#aeaeb2] justify-start items-center  inline-flex text-[#6c757d] text-base font-normal leading-normal"
                >
                  <img src={filter} alt="filter" />
                  <span className=" 100:hidden md:flex">Fiter</span>
                </div>
              </div>
              {filtered && (
                <>
                  <div className=" py-2.5 100:top-[325px] lg:top-[250px] absolute right-[60px] w-fit flex-col bg-white rounded-lg shadow border border-[#cfcfd2] h-fit divide-y b justify-center items-center  inline-flex text-[#6c757d] text-base font-normal leading-normal">
                    <h4
                      onClick={() => {
                        setFilterStatus("pending");
                        setFiltered(false);
                      }}
                      className={`w-full px-[16px] py-[10px] h-fit cursor-pointer ${filterStatus === "pending" ? "bg-[#f5f6f6]" : ""}`}
                    >
                      Pending Vendors
                    </h4>
                    <h4
                      onClick={() => {
                        setFilterStatus("rejected");
                        setFiltered(false);
                      }}
                      className={`w-full px-[16px] py-[10px] h-fit cursor-pointer ${filterStatus === "rejected" ? "bg-[#f5f6f6]" : ""}`}
                    >
                      Rejected Vendors
                    </h4>
                    <h4
                      onClick={() => {
                        setFilterStatus("approved");
                        setFiltered(false);
                      }}
                      className={`w-full px-[16px] py-[10px] h-fit cursor-pointer ${filterStatus === "approved" ? "bg-[#f5f6f6]" : ""}`}
                    >
                      Approved Vendors
                    </h4>
                    <h4
                      onClick={() => {
                        setFilterStatus("all");
                        setFiltered(false);
                      }}
                      className={`w-full px-[16px] py-[10px] h-fit cursor-pointer ${filterStatus === "all" ? "bg-[#f5f6f6]" : ""}`}
                    >
                      All Vendors
                    </h4>
                  </div>
                </>
              )}
              <div className=" self-stretch w-full border-t border-t-[#CDD1D4] flex justify-between items-center ">
                {/* ROW 1 */}
                <div className=" w-full flex flex-col justify-start items-start">
                  <div className="h-11 w-full px-6 py-3 bg-white justify-start items-center gap-3 inline-flex text-[#6b6a70] text-xs font-semibold font-openSans">Vendor ID</div>
                  {data
                    ?.filter((item) => filterStatus === "all" || item?.status === filterStatus) // Show all if filterStatus is empty

                    .map((item, index) => (
                      <div className={`h-[72px] w-full px-6 py-4 ${index % 2 === 0 ? "bg-[#f5f6f6]" : "bg-white"}  justify-start items-center gap-3 inline-flex text-[#4e4d51] text-sm font-normal font-openSans `}>{item.id}</div>
                    ))}
                </div>
                {/* ROW 2 */}
                <div className=" w-full flex flex-col justify-start items-start">
                  <div className="h-11 w-full px-6 py-3 bg-white justify-start items-center gap-3 inline-flex text-[#6b6a70] text-xs font-semibold font-openSans">Name</div>
                  {data
                    ?.filter((item) => filterStatus === "all" || item?.status === filterStatus) // Show all if filterStatus is empty

                    .map((item, index) => (
                      <div className={`h-[72px] w-full px-6 py-4 ${index % 2 === 0 ? "bg-[#f5f6f6]" : "bg-white"}  justify-start items-center gap-3 inline-flex text-[#4e4d51] text-sm font-normal font-openSans `}>{item.vendor_name}</div>
                    ))}
                </div>
                {/* ROW 3 */}
                <div className=" w-full flex flex-col justify-start items-start">
                  <div className="h-11 w-full px-6 py-3 bg-white justify-start items-center gap-3 inline-flex text-[#6b6a70] text-xs font-semibold font-openSans">Email</div>
                  {data
                    ?.filter((item) => filterStatus === "all" || item?.status === filterStatus) // Show all if filterStatus is empty

                    .map((item, index) => (
                      <div className={`h-[72px] w-full px-6 py-4 ${index % 2 === 0 ? "bg-[#f5f6f6]" : "bg-white"}  justify-start items-center gap-3 inline-flex text-[#4e4d51] text-sm font-normal font-openSans `}>{item.official_email}</div>
                    ))}
                </div>
                {/* ROW 4 */}
                <div className=" w-full flex flex-col justify-start items-start">
                  <div className="h-11 w-full px-6 py-3 bg-white justify-start items-center gap-3 inline-flex text-[#6b6a70] text-xs font-semibold font-openSans">Status</div>
                  {data
                    ?.filter((item) => filterStatus === "all" || item?.status === filterStatus) // Show all if filterStatus is empty

                    .map((item, index) => (
                      <div className={`h-[72px] w-full px-6 py-4 ${index % 2 === 0 ? "bg-[#f5f6f6]" : "bg-white"}  justify-start items-center gap-3 inline-flex  text-sm font-normal font-openSans `}>
                        <div className={`pl-1.5 pr-2 py-0.5 mix-blend-multiply rounded-2xl justify-center items-center gap-1 flex ${item?.status === "approved" ? "bg-[#ebfdf2]" : item?.status === "rejected" ? "bg-red-100" : "bg-[#ffffea]"}`}>
                          {item?.status === "approved" ? <Completed /> : item?.status === "rejected" ? <Reject /> : <Pending />}
                          <div className={`text-center ${item?.status === "approved" ? "text-[#1f7634]" : item?.status === "rejected" ? "text-red-500" : "text-[#bb6902]"} text-xs font-medium leading-[18px]`}>{item?.status}</div>
                        </div>
                      </div>
                    ))}
                </div>
                {/* ROW 5 */}
                <div className=" w-full flex flex-col justify-start items-start">
                  <div className="h-11 w-full px-6 py-3 bg-white justify-start items-center gap-3 inline-flex text-[#6b6a70] text-xs font-semibold font-openSans">Registration Date</div>
                  {data
                    ?.filter((item) => filterStatus === "all" || item?.status === filterStatus) // Show all if filterStatus is empty

                    .map((item, index) => (
                      <div className={`h-[72px] w-full px-6 py-4 ${index % 2 === 0 ? "bg-[#f5f6f6]" : "bg-white"}  justify-start items-center gap-3 inline-flex text-[#4e4d51] text-sm font-normal font-openSans `}>
                        {/* {moment(item?.date_created).format("DD MMMM YYYY")} */}
                        {moment(item.date_created, "YYYY-MM-DD HH:mm:ss", true).isValid() ? moment(item.date_created).format("DD MMMM YYYY") : "No Date Available"}
                      </div>
                    ))}
                </div>
                {/* ROW 6 */}
                <div className=" w-full flex flex-col justify-start items-start">
                  <div className="h-11 w-full px-6 py-3 bg-white justify-start items-center gap-3 inline-flex text-[#6b6a70] text-xs font-semibold font-openSans">Booth Type</div>
                  {data
                    ?.filter((item) => filterStatus === "all" || item?.status === filterStatus) // Show all if filterStatus is empty

                    .map((item, index) => (
                      <div className={`h-[72px] w-full px-6 py-4 ${index % 2 === 0 ? "bg-[#f5f6f6]" : "bg-white"}  justify-start items-center gap-3 inline-flex text-[#4e4d51] text-sm font-normal font-openSans `}>{item.booth_type}</div>
                    ))}
                </div>
                {/* ROW 7 */}
                <div className=" w-full flex flex-col justify-start items-start">
                  <div className="h-11 w-full px-6 py-3 bg-white justify-start items-center gap-3 inline-flex text-[#6b6a70] text-xs font-semibold font-openSans"></div>
                  {data
                    ?.filter((item) => filterStatus === "all" || item?.status === filterStatus) // Show all if filterStatus is empty

                    .map((item, index) => (
                      <div
                        onClick={() => {
                          setIsSelected(item);
                          setVendorDetails(true);
                        }}
                        className={`h-[72px] w-full px-6 py-4 ${index % 2 === 0 ? "bg-[#f5f6f6]" : "bg-white"}  justify-start items-center gap-3 inline-flex text-[#fe8734] text-sm font-medium cursor-pointer font-openSans `}
                      >
                        View
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* {approve && (
        <>
          <div className=" fixed z-[50] flex justify-center items-center w-full h-full bg-black bg-opacity-40 top-0 left-0">
            <div className=" 100:w-[80%] h-fit sm:w-[500px] gap-[24px] px-4 py-6 bg-[#f5f5f6] rounded-[20px] flex-col justify-start items-center  inline-flex">
              <div className="w-full px-2 justify-between items-center inline-flex">
                <h2 className="self-stretch text-center text-[#3c3b3e] text-[24px] font-semibold font-playfair">Approve Vendor</h2>

                <div onClick={() => setApprove(false)} className="w-12 cursor-pointer h-12 justify-center flex  items-center bg-black/30 rounded-[100px] backdrop-blur-[31.63px]  ">
                  <img className=" " src={cancel} alt="Cancel" />
                </div>
              </div>
              <form onSubmit={formik.handleSubmit} className=" w-full    pb-[10px]  flex-col justify-start items-start gap-4 inline-flex">
                <label className="flex-col w-full justify-start items-start gap-1.5 inline-flex text-[#4e4d51] text-sm font-semibold font-openSans" htmlFor="Vendor ID">
                  Vendor ID
                  <input
                    type="number"
                    placeholder="Vendor ID"
                    className="outline-[#fe8734] h-[43px] w-full pl-4 pr-3.5 py-3 bg-white rounded-[20px] shadow border border-[#aeaeb2] justify-start items-center"
                    id="vendor_id"
                    inputMode="numeric"
                    {...formik.getFieldProps("vendor_id")}
                  />
                  {formik.touched.vendor_id && formik.errors.vendor_id ? <div className=" text-red-400 text-[14px] font-openSans font-medium">{formik.errors.vendor_id}</div> : null}
                </label>
                <div className="w-full flex-col-reverse gap-[16px] flex justify-between items-center">
                  {/* Reject Button */}
      {/* <button
                    className="h-fit w-full px-4 py-2 border-[#fe8734] border rounded-[20px] justify-center text-[#e30a2e] text-[14px] font-semibold font-openSans items-center inline-flex"
                    onClick={() => {
                      handleVendorReject(formik?.vendor_id, "rejected");
                      console.log(formik?.vendor_id);
                    }} // Rejection action
                  >
                    Reject
                  </button> */}
      {/* Approve Button */}
      {/* <button
                    className="h-fit px-4 py-2 w-full bg-[#fe8734] rounded-[20px] justify-center text-[#f5f5f6] text-[14px] font-semibold font-openSans items-center inline-flex"
                    onClick={() => handleVendorApprove(isSelected?.id, isSelected?.official_email, isSelected?.vendor_name, "approved")} // Approval action
                  >
                    Approve
                  </button>
                </div> */}
      {/* <button type="submit" className="w-full  mt-[24px]">
                  <div className="w-full h-12 px-8 py-2 bg-[#fe8734] rounded-[20px] justify-center text-[#f5f5f6] text-xl font-semibold font-openSans items-center inline-flex">{isLoading ? "Submitting..." : " Submit"}</div>
                </button> */}
      {/* </form>
            </div>
          </div>
        </>
      )} */}{" "}
      {/* } */}
      {save && (
        <>
          <div className=" fixed z-[50] flex justify-center items-center w-full h-full bg-black bg-opacity-40 top-0 left-0">
            <div className=" 100:w-[80%] h-fit sm:w-[500px]  px-4 py-6 bg-[#f5f5f6] rounded-[20px] flex-col justify-start items-center  inline-flex">
              <div className="w-full px-2 justify-end items-center inline-flex">
                <div onClick={() => setSave(false)} className="w-12 cursor-pointer h-12 justify-center flex  items-center bg-black/30 rounded-[100px] backdrop-blur-[31.63px]  ">
                  <img className=" " src={cancel} alt="Cancel" />
                </div>
              </div>

              <div className=" flex-col  w-full  justify-center items-center gap-[24px] inline-flex">
                <div className="w-12 h-12 flex justify-center items-center bg-[#ade4ac] rounded-[28px] border-8 border-[#e7f6ec]">
                  <img src={tick} alt="tick" />
                </div>
                <div className=" w-full px-2 flex-col justify-center items-center gap-2 inline-flex">
                  <h3 className="text-[#3c3b3e] text-xl font-semibold font-openSans">Successful</h3>
                  <p className=" text-center text-[#6b6a70] text-sm font-normal font-openSans">{responseMessage}</p>
                </div>
                <div className=" w-full flex justify-center items-center">
                  <button>
                    <div
                      onClick={() => {
                        setSave(false);
                        setLoading(false);
                      }}
                      className="w-[250px] h-12 px-8 py-2 bg-[#fe8734] rounded-[20px] justify-center text-[#f5f5f6] text-xl font-semibold font-openSans items-center inline-flex"
                    >
                      Ok
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {vendorDetails && (
        <>
          <div className=" fixed z-[50] flex justify-center items-center w-full h-full bg-black bg-opacity-40 top-0 left-0">
            <div className=" 100:w-[80%] h-fit sm:w-[500px]   p-2.5 bg-[#f5f5f6]  rounded-[20px] flex-col justify-start items-center  inline-flex">
              <div className="h-[550px]  w-full p-6 bg-[#f0faff] rounded-[20px] flex-col justify-start items-start gap-8 inline-flex">
                <div className="w-full justify-between items-center inline-flex">
                  <h2 className="self-stretch text-center text-[#3c3b3e] text-[20px] font-semibold font-playfair">Vendor Details</h2>

                  <div onClick={() => setVendorDetails(false)} className="w-12 cursor-pointer h-12 justify-center flex  items-center bg-black/30 rounded-[100px] backdrop-blur-[31.63px]  ">
                    <img className=" " src={cancel} alt="Cancel" />
                  </div>
                </div>
                <div className="w-full h-fit overflow-y-scroll flex-col justify-start items-start gap-4 inline-flex">
                  <div className="self-stretch  flex-col justify-start items-start gap-2 flex">
                    <h4 className="text-[#3c3b3e] text-2xl font-semibold font-openSans">{isSelected?.vendor_name}</h4>
                  </div>
                  <div className="w-full h-px bg-[#e6e6e7]" />
                  <div className=" flex-col justify-start items-start gap-2.5 inline-flex">
                    <h4 className="text-[#6b6a70] w-full text-xs font-semibold font-openSans ">
                      Vendor Category: <span className="text-[#86858b] text-xs font-normal font-openSans ">{isSelected?.vendor_category}</span>
                    </h4>
                    <h4 className="text-[#6b6a70] w-full text-xs font-semibold font-openSans ">
                      Vendor ID: <span className="text-[#86858b] text-xs font-normal font-openSans ">{isSelected?.id}</span>
                    </h4>
                    <div className="text-[#6b6a70] text-xs font-semibold font-openSans ">
                      Booth Type: <span className="text-[#86858b] text-xs font-normal font-openSans ">{isSelected?.booth_type}</span>
                    </div>
                    <div className="text-[#6b6a70] text-xs font-semibold font-openSans ">
                      Instagram: <span className="text-[#86858b] text-xs font-normal font-openSans ">{isSelected?.instagram_handle}</span>
                    </div>
                  </div>
                  <div className="w-full h-px bg-[#e6e6e7]" />
                  <div className=" flex-col justify-start items-start gap-2.5 inline-flex">
                    <h4 className="text-[#6b6a70] w-full text-xs font-semibold font-openSans ">
                      Vendor Email: <span className="text-[#86858b] text-xs font-normal font-openSans ">{isSelected?.official_email}</span>
                    </h4>
                    <h4 className="text-[#6b6a70] w-full text-xs font-semibold font-openSans ">
                      Phone Number: <span className="text-[#86858b] text-xs font-normal font-openSans ">{isSelected?.official_phone_number}</span>
                    </h4>
                  </div>
                  <div className="w-full h-px bg-[#e6e6e7]" />
                  <div className=" flex-col justify-start items-start gap-2.5 inline-flex">
                    <div className="text-[#6b6a70] flex items-center justify-start gap-[6px] text-xs font-semibold font-openSans ">
                      Status:{" "}
                      <div
                        className={`pl-1.5 pr-2 py-0.5 mix-blend-multiply rounded-2xl justify-center items-center gap-1 flex ${isSelected?.status === "approved" ? "bg-[#ebfdf2]" : isSelected?.status === "rejected" ? "bg-red-100" : "bg-[#ffffea]"}`}
                      >
                        {isSelected?.status === "approved" ? <Completed /> : isSelected?.status === "rejected" ? <Reject /> : <Pending />}
                        <div className={`text-center ${isSelected?.status === "approved" ? "text-[#1f7634]" : isSelected?.status === "rejected" ? "text-red-500" : "text-[#bb6902]"} text-xs font-medium leading-[18px]`}>{isSelected?.status}</div>
                      </div>
                    </div>
                    <h4 className="text-[#6b6a70] w-full text-xs font-semibold font-openSans ">
                      Registered Date: <span className="text-[#86858b] text-xs font-normal font-openSans ">{isSelected?.date_created}</span>
                    </h4>
                    <h4 className="text-[#6b6a70] w-full text-xs font-semibold font-openSans ">
                      Registered Approved: <span className="text-[#86858b] text-xs font-normal font-openSans ">{isSelected?.date_approved}</span>
                    </h4>
                    <h4 className="text-[#6b6a70] w-full text-xs font-semibold font-openSans ">
                      Approver: <span className="text-[#86858b] text-xs font-normal font-openSans ">{isSelected?.approved_by}</span>
                    </h4>
                  </div>
                </div>
                {isSelected?.status !== "approved" && isSelected?.status !== "rejected" && (
                  <div className="w-full flex justify-between items-center">
                    {/* Reject Button */}
                    <button
                      className="h-fit px-4 py-2 border-[#fe8734] border rounded-[20px] justify-center text-[#e30a2e] text-[14px] font-semibold font-openSans items-center inline-flex"
                      onClick={() => {
                        handleVendorReject(isSelected?.id, isSelected?.official_email, "rejected");
                        console.log(isSelected?.id, isSelected?.official_email);
                      }} // Rejection action
                    >
                      Reject
                    </button>

                    {/* Approve Button */}
                    <button
                      className="h-fit px-4 py-2 bg-[#fe8734] rounded-[20px] justify-center text-[#f5f5f6] text-[14px] font-semibold font-openSans items-center inline-flex"
                      onClick={() => handleVendorApprove(isSelected?.id, isSelected?.official_email, isSelected?.vendor_name, "approved")} // Approval action
                    >
                      Approve
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default Vendor;
