import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ReactComponent as Star } from "../Images/svg/star.svg";
import logo from "../Images/photos/ThebabyConventionColorLogo.png";
import arrow from "../Images/svg/arrowleft.svg";
import pic from "../Images/photos/form.png";
import cancel from "../Images/svg/Cancel.svg";
import tick from "../Images/svg/ticked.svg";
import Select from "react-select";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import PrivacyVendor from "../PrivacyVendor";

const options = [
  { value: "Child personal and skin care", label: "Child personal and skin care" },
  { value: "Books and toys food", label: "Books and toys" },
  { value: "Children clothing ", label: "Children clothing " },
  { value: "Nutrition and beverage ", label: "Nutrition and beverage " },
  // { value: "Food ", label: "Food " },
  { value: "Pharmaceutical ", label: "Pharmaceutical " },
  { value: "Others ", label: "Others " },
];
function Vendor() {
  const validationSchema = Yup.object({
    vendor_name: Yup.string().required("Vendor Full Name is required"),
    official_email: Yup.string().email("Invalid email address").required("Email is required"),
    official_phone_number: Yup.string().matches(/^\d+$/, "Phone number must contain only digits").required("Phone number is required"),
    instagram_handle: Yup.string().required("Vendor instagram handle is required"),
    vendor_category: Yup.object().required("Please select a category option"),
    // early_bird: Yup.string().when("showEarlyBird", {
    //   is: true,
    //   then: Yup.string().required("Please select an early bird option"),
    // }),
    // regular: Yup.string().when("showEarlyBird", {
    //   is: false,
    //   then: Yup.string().required("Please select a regular option"),
    // }),
    terms: Yup.string().required("Terms of use is required"),
  });
  const [save, setSave] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const [privacy, setPrivacy] = useState(false);
  // const [showEarlyBird, setShowEarlyBird] = useState(true);
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      vendor_name: "",
      official_email: "",
      official_phone_number: "",
      instagram_handle: "",
      vendor_category: null,
      // early_bird: "",
      // regular: "",
      terms: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      const formData = {
        vendor_name: values.vendor_name,
        official_email: values.official_email,
        official_phone_number: values.official_phone_number,
        instagram_handle: values.instagram_handle,
        vendor_category: values.vendor_category.label,
        // early_bird: showEarlyBird ? values.early_bird : null,
        // regular: showEarlyBird ? null : values.regular,
        // terms: null,
      };
      console.log(formData);
      try {
        const response = await axios.post("https://www.thebabyconvention.com/wbe/api/add_vendors.php", formData);
        console.log(response);
        if (response.data.responsecode === "200") {
          setSave(true);
          setResponseMessage(response.data.responsemessage);
          // formik.resetForm(); // Reset form fields
          // setTimeout(() => {
          //   navigate("/"); // Redirect to home page after a short delay to ensure form reset
          // }, 3000);
        } else {
          setResponseMessage("Registration failed. Please try again.");
        }
        return response.data;
      } catch (error) {
        console.error("Error registering user:", error);
        setResponseMessage("An error occurred. Please try again later.");
      }
    },
  });
  // useEffect(() => {
  //   const currentDate = new Date(); //"2024-10-16"
  //   const cutoffDate = new Date(currentDate.getFullYear(), 9, 15); // October is month 9 (0-indexed)
  //   if (currentDate >= cutoffDate) {
  //     setShowEarlyBird(false);
  //   }
  // }, []);
  // const handleSelectedMasterClassChange = (e) => {
  //   formik.handleChange(e);
  // };
  return (
    <>
      <div className=" w-full flex justify-between items-start">
        <div style={{ backgroundImage: ` url(${pic})`, backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundColor: "rgba(254, 135, 52) 60%" }} className="100:hidden xl:flex w-[100vh] fixed top-0 left-0  h-full">
          <div className=" w-full bg-[#FE8734]/60 h-full">
            <div className=" w-full   flex justify-end items-end h-full" style={{ backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.2) 20%, rgba(0, 0, 0, 0.7) 100%)` }}>
              <div className="p-10 sm:p-[50px] md:p-[75px] lg:p-[50px] w-full gap-[48px] flex flex-col justify-start items-start">
                <Star />
                <div className="flex-col justify-start items-center gap-6 inline-flex">
                  <h1 className="self-stretch text-white text-[32px] sm:text-[40px] md:text-[48px] lg:text-[64px] font-semibold font-playfair leading-tight">Join the Baby Convention Today!</h1>
                  <p className="self-stretch text-[#cfcfd2] text-lg font-normal font-openSans leading-[27px]">
                    Don't miss out on the ultimate event for expectant parents and families with young children! Our Baby Convention offers a wealth of resources, expert advice, product showcases, and fun activities.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className=" h-fit pb-[50px] flex flex-col justify-start items-center gap-[62px] w-full 100:pl-[40px] xl:pl-[105vh] pr-[40px]">
          <div className=" h-[100px] left-0 top-0 bg-white/40 w-full 100:px-[50px] xl:px-0  100:fixed xl:static   flex  justify-between items-center">
            <Link to="/instruction">
              <div className="w-fit flex justify-center items-center gap-2">
                <div>
                  <img className="w-full" src={arrow} alt="back" />
                </div>
                <div className="text-[#fe8734] 100:hidden md:block text-base font-semibold font-openSans">Back</div>
              </div>
            </Link>
            <div className=" 100:w-[60px] iphone:w-[80px] iphone:h-[54.89px]">
              <img className="w-full" src={logo} alt="The Baby Convention" />
            </div>
            <div className="w-[60px] 100:hidden md:block"></div>
          </div>
          <div className="sm:w-[450px] 100:w-full 100:mt-[100px] xl:mt-0 flex-col justify-start items-start gap-8 inline-flex">
            <h3 className="self-stretch text-[#3c3b3e] text-[32px] font-semibold font-playfair">Sign up</h3>
            <form onSubmit={formik.handleSubmit} className=" w-full   pb-[10px]  flex-col justify-start items-start gap-[24px] inline-flex">
              <label className="flex-col w-full  justify-start items-start gap-1.5 inline-flex text-[#4e4d51] text-sm font-semibold font-openSans" htmlFor="Vendor Name">
                Vendor Name
                <input
                  type="text"
                  placeholder="Full Name"
                  className="outline-[#fe8734] h-[43px] w-full pl-4 pr-3.5 py-3 bg-white rounded-[20px] shadow border border-[#aeaeb2] justify-start items-center"
                  id="vendor_name"
                  {...formik.getFieldProps("vendor_name")}
                />
                {formik.touched.vendor_name && formik.errors.vendor_name ? <div className=" text-red-400 text-[14px] font-openSans font-medium">{formik.errors.vendor_name}</div> : null}
              </label>

              <label className="flex-col w-full justify-start items-start gap-1.5 inline-flex text-[#4e4d51] text-sm font-semibold font-openSans" htmlFor="Email Address">
                Official Email
                <input
                  type="email"
                  placeholder="Email Address"
                  className="outline-[#fe8734] h-[43px] w-full pl-4 pr-3.5 py-3 bg-white rounded-[20px] shadow border border-[#aeaeb2] justify-start items-center"
                  id="official_email"
                  {...formik.getFieldProps("official_email")}
                />
                {formik.touched.official_email && formik.errors.official_email ? <div className=" text-red-400 text-[14px] font-openSans font-medium">{formik.errors.official_email}</div> : null}
              </label>
              <label className="flex-col w-full justify-start items-start gap-1.5 inline-flex text-[#4e4d51] text-sm font-semibold font-openSans" htmlFor="Phone number">
                Phone number of Business
                <input
                  type="tel"
                  placeholder="Phone number of business"
                  inputMode="numeric"
                  className="outline-[#fe8734] h-[43px] w-full pl-4 pr-3.5 py-3 bg-white rounded-[20px] shadow border border-[#aeaeb2] justify-start items-center"
                  id="official_phone_number"
                  {...formik.getFieldProps("official_phone_number")}
                />
                {formik.touched.official_phone_number && formik.errors.official_phone_number ? <div className=" text-red-400 text-[14px] font-openSans font-medium">{formik.errors.official_phone_number}</div> : null}
              </label>
              <label className="flex-col w-full justify-start items-start gap-1.5 inline-flex text-[#4e4d51] text-sm font-semibold font-openSans" htmlFor="Phone number">
                Vendor Instagram Handle
                <input
                  type="text"
                  placeholder="Vendor instagram handle"
                  className="outline-[#fe8734] h-[43px] w-full pl-4 pr-3.5 py-3 bg-white rounded-[20px] shadow border border-[#aeaeb2] justify-start items-center"
                  id="instagram_handle"
                  {...formik.getFieldProps("instagram_handle")}
                />
                {formik.touched.instagram_handle && formik.errors.instagram_handle ? <div className=" text-red-400 text-[14px] font-openSans font-medium">{formik.errors.instagram_handle}</div> : null}
              </label>
              <label className="flex-col w-full justify-start items-start gap-1.5 inline-flex text-[#4e4d51] text-sm font-semibold font-openSans" htmlFor="Category of vendor">
                Category of vendor
                <Select
                  name="Category of vendor"
                  className="  placeholder-ellipsis placeholder-gray-500 w-full outline-[#fe8734] "
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary25: "#fff6ed",
                      placeholder: "#6B6A70",
                      primary: "#fe8734",
                    },
                  })}
                  styles={{
                    control: (baseStyles) => ({
                      ...baseStyles,
                      width: "100%",
                      background: "#fff",
                      borderRadius: "20px",
                      height: "43px",
                      padding: "3px",
                      boxShadow: "0px 1px 3px 0px rgb(0,0, 0, 0.2)",
                      borderColor: "#aeaeb2",
                      borderWidth: "1px",
                      outline: "1px",
                      fontFamily: "Open Sans",
                      fontWeight: "500",
                      fontSize: "14px",
                    }),
                  }}
                  placeholder="Select Category of vendor"
                  options={options}
                  value={formik.values.vendor_category}
                  onChange={(option) => formik.setFieldValue("vendor_category", option)}
                />
                {formik.touched.vendor_category && formik.errors.vendor_category ? <div className=" text-red-400 text-base">{formik.errors.vendor_category.label}</div> : null}
              </label>
              {/* {showEarlyBird ? (
                <label className="flex-col  w-full justify-start items-start gap-3 inline-flex text-[#4e4d51] text-sm font-semibold font-openSans" htmlFor="attend">
                  Early Bird Discounts Available from (September 1st to October 15th)
                  {earlyBird.map((item) => (
                    <label className=" flex justify-start items-center gap-[8px] text-[#6b6a70] text-sm font-normal font-openSans leading-tight" htmlFor={item.title}>
                      <input
                        type="radio"
                        value={item.title}
                        className="w-4 h-4 appearance-none checked:bg-[#fe8734] bg-white rounded-lg border border-[#cfcfd2]"
                        id={item.id}
                        name="early_bird"
                        onChange={handleSelectedMasterClassChange}
                        checked={formik.values.early_bird === item.title}
                      />
                      {item.title}
                    </label>
                  ))}
                  {formik.touched.early_bird && formik.errors.early_bird ? <div className="text-red-400 text-[14px] font-openSans font-medium">{formik.errors.early_bird}</div> : null}
                </label>
              ) : (
                <label className="flex-col  w-full justify-start items-start gap-3 inline-flex text-[#4e4d51] text-sm font-semibold font-openSans" htmlFor="attend">
                  Regular Fee Available from (October 16th to November 1st)
                  {regular.map((item) => (
                    <label className=" flex justify-start items-center gap-[8px] text-[#6b6a70] text-sm font-normal font-openSans leading-tight" htmlFor={item.title}>
                      <input
                        type="radio"
                        value={item.title}
                        className="w-4 h-4 appearance-none checked:bg-[#fe8734] bg-white rounded-lg border border-[#cfcfd2]"
                        id={item.id}
                        name="regular"
                        onChange={handleSelectedMasterClassChange}
                        checked={formik.values.regular === item.title}
                      />
                      {item.title}
                    </label>
                  ))}
                  {formik.touched.regular && formik.errors.regular ? <div className="text-red-400 text-[14px] font-openSans font-medium">{formik.errors.regular}</div> : null}
                </label>
              )} */}
              <p className="text-[#6b6a70] text-xs font-normal  font-openSans">This expo is currently only open to exhibitors in Lagos. </p>
              <label className="w-full justify-start items-center gap-[12px] inline-flex text-[#86858b] text-base font-normal font-openSans" htmlFor="Message">
                <input
                  className="bg-white  rounded-[6px] h-[20px] w-[20px] appearance-none checked:bg-[#fe8734]   border border-[#aeaeb2]"
                  type="checkbox"
                  id="terms"
                  {...formik.getFieldProps("terms")}
                  onChange={formik.handleChange}
                  checked={formik.values.terms}
                />
                <p className=" ">
                  You agree to our friendly{" "}
                  <span onClick={() => setPrivacy(true)} className=" cursor-pointer underline">
                    Terms of use
                  </span>
                  .
                </p>
              </label>
              {formik.touched.terms && formik.errors.terms ? <div className=" text-red-400 text-[14px] font-openSans font-medium">{formik.errors.terms}</div> : null}
              <button type="submit" className="w-full mt-[24px]">
                <div className="w-full h-12 px-8 py-2 bg-[#fe8734] rounded-[20px] justify-center text-[#f5f5f6] text-xl font-semibold font-openSans items-center inline-flex">{isLoading ? "Submitting..." : " Submit"}</div>
              </button>
            </form>
          </div>
        </div>
      </div>
      {save && (
        <>
          <div className=" fixed z-[50] flex justify-center items-center w-full h-full bg-black bg-opacity-40 top-0 left-0">
            <div className=" 100:w-[80%] h-fit sm:w-[500px]  px-4 py-6 bg-[#f5f5f6] rounded-[20px] flex-col justify-start items-center  inline-flex">
              <div className="w-full px-2 justify-end items-center inline-flex">
                <div onClick={() => setSave(false)} className="w-12 cursor-pointer h-12 justify-center flex  items-center bg-black/30 rounded-[100px] backdrop-blur-[31.63px]  ">
                  <img className=" " src={cancel} alt="Cancel" />
                </div>
              </div>

              <div className=" flex-col  w-full  justify-center items-center gap-[24px] inline-flex">
                <div className="w-12 h-12 flex justify-center items-center bg-[#ade4ac] rounded-[28px] border-8 border-[#e7f6ec]">
                  <img src={tick} alt="tick" />
                </div>
                <div className=" w-full px-2 flex-col justify-center items-center gap-2 inline-flex">
                  <h3 className="text-[#3c3b3e] text-xl font-semibold font-openSans">Successful</h3>
                  <p className=" text-center text-[#6b6a70] text-sm font-normal font-openSans">{responseMessage}</p>
                </div>
                <div className=" w-full flex justify-center items-center">
                  <button>
                    <div
                      onClick={() => {
                        setSave(false);
                        setLoading(false);
                        formik.resetForm(); // Reset form fields
                        window.scrollTo(0, 0);
                        navigate("/");
                      }}
                      className="w-[250px] h-12 px-8 py-2 bg-[#fe8734] rounded-[20px] justify-center text-[#f5f5f6] text-xl font-semibold font-openSans items-center inline-flex"
                    >
                      Ok
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {privacy && <PrivacyVendor setPrivacy={setPrivacy} privacy={privacy} />}
    </>
  );
}

export default Vendor;
