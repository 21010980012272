function NewsLetter() {
  return (
    <>
      <div className="w-full h-fit gap-[60px]  py-[96px] 100:px-[24px] lg:px-[64px]  flex justify-center items-center">
        <div className="xl:w-[1200px] 100:w-[90%] h-fit 100:p-[48px] md:p-16 bg-[#fff6ed] rounded-2xl flex-col justify-center items-center gap-8 flex">
          <div className="100:w-full xl:w-[508px] flex-col  justify-center text-center items-center gap-[10px] flex">
            <h3 className="text-[#3c3b3e] text-[32px] font-semibold font-playfair">Stay Informed with Our Newsletter</h3>
            <p className=" text-center text-[#6b6a70] text-lg font-normal font-openSans">Sign Up for the Latest Updates on Events, Products, and Parenting Tips</p>
          </div>
          <form method="" className=" 100:flex-col sm:flex-row 100:w-full xl:w-[540px] justify-start items-start gap-4 inline-flex">
            <input type="email" placeholder="Enter your email" className="w-full outline-[#fe8734] h-12 pl-4 pr-3.5 py-3 bg-white rounded-[20px] shadow border border-[#aeaeb2] text-[#6b6a70] text-base font-normal font-openSans leading-normal" />
            <button type="submit" className="h-12 100:w-full sm:w-fit px-8 py-2 bg-[#fe8734] rounded-[20px] justify-center items-center inline-flex text-[#f5f5f6] text-xl font-semibold font-openSans">
              Subscribe
            </button>
          </form>
        </div>
      </div>
    </>
  );
}

export default NewsLetter;
