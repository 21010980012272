// import { ReactComponent as Home } from "../Images/svg/home.svg";
import { ReactComponent as Users } from "../Images/svg/users.svg";
import { Link, NavLink, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
// import axios from "axios";
import { ReactComponent as Vendor } from "../Images/svg/vendor.svg";
import { ReactComponent as Sponsor } from "../Images/svg/sponsor.svg";
import logo from "../Images/photos/ThebabyConventionColorLogo.png";
import burger from "../Images/svg/HamburgerMenu copy.svg";
import cancel from "../Images/svg/Cancel.svg";
import logout from "../Images/svg/logout.svg";
import user from "../Images/photos/userID.png";

function SideBar() {
  const [hamburger, setHamburger] = useState(false);
  // const [navBar, setNavBar] = useState(false);
  const location = useLocation();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true); // Loading state to track async operations
  const [error, setError] = useState(null); // Error state to handle any login issues

  useEffect(() => {
    const storedUserData = localStorage.getItem("babyConv");

    // paymentData
    if (storedUserData) {
      const parsedData = JSON.parse(storedUserData);
      setData(parsedData); // Set user data in state
    } else {
      setError("No user data found. Please login again.");
    }

    setLoading(false); // Stop loading once data is retrieved
  }, []);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     const dataSend = {
  //       username: "",
  //       password: "",
  //     };

  //     await fetch("https://thebabyconvention.com/wbe/api/login.php", {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify(dataSend),
  //     }).then(async (res) => {
  //       setLoading(false);
  //       const responseData = await res.json();
  //       if (responseData.data) {
  //         console.log("is response", responseData);
  //         setData(responseData);
  //         localStorage.setItem("babyConv", JSON.stringify(responseData.data));
  //       } else {
  //         setData("Login failed. Please try again.");
  //       }
  //     });

  //     // try {
  //     //   const res = await axios.post("https://thebabyconvention.com/wbe/api/login.php", dataSend, {
  //     //     headers: {
  //     //       "Content-Type": "application/json",
  //     //     },
  //     //   });

  //     //   console.log("Login response: ", res);

  //     //   // Assuming that user details are part of res.data.user
  //     //   if (res.data && res.data.data) {
  //     //     setData(res.data.data); // Set user data in state
  //     //   } else {
  //     //     throw new Error("User details not found in response");
  //     //   }
  //     // } catch (error) {
  //     //   console.error("Login error:", error);
  //     //   setError("Unable to fetch user details. Please try again.");
  //     // } finally {
  //     //   setLoading(false); // Stop loading spinner after fetching data
  //     // }
  //   };

  //   fetchData();
  // }, []);
  const menu = [
    // {
    //   id: 1,
    //   path: "/admin/dashboard",
    //   name: "Dashboard Overview",
    //   icon: <Home />,
    // },
    {
      id: 2,
      path: "/admin/user-management",
      name: "User Management",
      icon: <Users />,
    },
    {
      id: 3,
      path: "/admin/vendor",
      name: "Vendors",
      icon: <Vendor />,
    },
    {
      id: 4,
      path: "/admin/sponsor",
      name: "Sponsors",
      icon: <Sponsor />,
    },
  ];
  return (
    <>
      <div className="h-screen fixed 100:hidden xl:flex top-0 left-0 w-[265px] px-1 py-5 bg-white shadow flex-col justify-start items-center gap-6 inline-flex">
        <div className=" 100:w-[60px] iphone:w-[90px] iphone:h-[54.89px]">
          <img className="w-full" src={logo} alt="The Baby Convention" />
        </div>
        <div className="w-full px-2 pt-6 flex-col justify-start items-start gap-1 inline-flex">
          {menu.map((item) => (
            <NavLink title={item.name} className="w-full" key={item.id} to={item.path}>
              <div className={`px-3 py-2 h-9 rounded-2xl  w-full text-[#4e4d51] text-sm font-semibold font-openSans  ${item.path === location.pathname ? "bg-[#fff6ed]" : "bg-[#f5f5f6]"}  justify-start items-center gap-3 flex `}>
                <span>{item.icon}</span>
                <h3>{item.name}</h3>
              </div>
            </NavLink>
          ))}
        </div>
        <div className="overflow-hidden h-full w-full px-2 flex-col flex gap-[16px] py-[24px] justify-end items-end">
          <div className=" w-full h-px bg-[#F5F5F6]" />
          <div className=" w-full   justify-between items-start inline-flex">
            <Link onClick={() => window.scrollTo(0, 0)} className=" w-fit" to="/admin/profile">
              <div className="w-[60%]  justify-start items-center gap-2 inline-flex">
                <img src={user} alt="user" className=" rounded-full object-cover h-10 w-10" />

                <div className="w-full flex-col justify-start items-start inline-flex">
                  <h4 className="text-[#3C3B3E] font-openSans text-sm font-medium  leading-tight">
                    {/* {data?.name} */}
                    {data?.name || "Guest"}
                  </h4>
                  <h5 className="text-[#6B6A70] text-ellipsis w-full  font-openSans text-[10px] font-normal leading-tight overflow-hidden text-overflow-ellipsis whitespace-nowrap">
                    {/* {data?.email} */}
                    {data?.email || "No email available"}
                  </h5>
                </div>
              </div>
            </Link>
            <Link className=" w-full" onClick={() => window.scrollTo(0, 0)} to="/login">
              <div className=" h-[20px] w-[20px]">
                <img className="w-full" src={logout} alt="logout" />
              </div>
            </Link>
          </div>
        </div>
      </div>
      <div className="z-10 bg-[#f6f6f6]/70 backdrop-blur-sm fixed p-[20px] h-[100px] w-full 100:flex xl:hidden flex justify-between items-center">
        <div className={`w-full   flex justify-between items-center px-[30px] `}>
          <div className=" 100:w-[60px] iphone:w-[90px] iphone:h-[54.89px]">
            <img className="w-full" src={logo} alt="The Baby Convention" />
          </div>
          <div className=" w-fit justify-start items-center gap-3 inline-flex">
            <img className=" cursor-pointer" onClick={() => setHamburger(true)} alt="menu" src={burger} />
          </div>
        </div>
      </div>
      {hamburger && (
        <>
          <div className=" fixed top-0 left-0 w-full h-full z-30 bg-[#344053]/60 backdrop-blur-lg  justify-between items-start inline-flex">
            <div className="h-screen inset-3  w-[258px] px-1 py-5 bg-white shadow flex-col justify-start items-center gap-6 inline-flex">
              <div className=" 100:w-[60px] iphone:w-[90px] iphone:h-[54.89px]">
                <img className="w-full" src={logo} alt="The Baby Convention" />
              </div>
              <div className="w-full px-2 pt-6 flex-col justify-start items-start gap-1 inline-flex">
                {menu.map((item) => (
                  <NavLink title={item.name} className="w-full" key={item.id} to={item.path}>
                    <div className={`px-3 py-2 h-9 rounded-2xl  w-full text-[#4e4d51] text-sm font-semibold font-openSans  ${item.path === location.pathname ? "bg-[#fff6ed]" : "bg-[#f5f5f6]"}  justify-start items-center gap-3 flex `}>
                      <span>{item.icon}</span>
                      <h3>{item.name}</h3>
                    </div>
                  </NavLink>
                ))}
              </div>
              <div className=" h-full w-full px-2 flex-col flex gap-[16px] py-[24px] justify-end items-end">
                <div className=" w-full h-px bg-[#F5F5F6]"></div>
                <div className="h-10 w-full  justify-between items-start inline-flex">
                  <Link onClick={() => window.scrollTo(0, 0)} className=" w-full" to="/admin/profile">
                    <div className="h-10 w-full justify-start items-center gap-3 inline-flex">
                      <img src={user} alt="user" className=" rounded-full object-cover h-10 w-10" />

                      <div className="h-10 flex-col justify-start items-start inline-flex">
                        <h4 className="text-[#3C3B3E] font-openSans text-sm font-medium  leading-tight">{/* {firstName} {lastName} */} Candy Obi</h4>
                        <h5 className="text-[#6B6A70] font-openSans text-[12px] font-normal  leading-tight">{/* {email} */} obiijeoma32@gmail.com</h5>
                      </div>
                    </div>
                  </Link>
                  <Link onClick={() => window.scrollTo(0, 0)} to="/login">
                    <img src={logout} alt="logout" />
                  </Link>
                </div>
              </div>
            </div>
            <section className="  pt-[25px] pr-[30px] ">
              <div onClick={() => setHamburger(false)} className="w-12 h-12 cursor-pointer  justify-center flex  items-center bg-gray-400 rounded-3xl  ">
                <img className=" " src={cancel} alt="Cancel" />
              </div>
            </section>
          </div>
        </>
      )}
    </>
  );
}

export default SideBar;
