import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Header from "../Header";
import logo from "../../Images/photos/ThebabyConventionColorLogo.png";
import parent1 from "../../Images/photos/img1.png";
import parent2 from "../../Images/photos/2.jpeg";
import parent3 from "../../Images/photos/3.png";
import parent4 from "../../Images/photos/4.png";
import up from "../../Images/svg/arrowUp.svg";
import LatestNews from "./NavbarComponents/LatestNews";
import UpcomingEvent from "./NavbarComponents/UpcomingEvent";
import FeaturedProducts from "./NavbarComponents/FeaturedProducts";
import Resources from "./NavbarComponents/Resources";
import Stories from "./NavbarComponents/Stories";
import Founder from "./NavbarComponents/Founder";
import NewsLetter from "./NavbarComponents/NewsLetter";
import Footer from "./NavbarComponents/Footer";

function Home() {
  const [currentImage, setCurrentImage] = useState(0);
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 600) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // This makes the scroll smooth
    });
  };
  const images = [
    {
      id: 1,
      name: parent1,
      box1: "#FCF3F8",
      box2: "#FAE9F2",
    },
    {
      id: 2,
      name: parent2,
      box1: "#FFF6ED",
      box2: "#FFEBD4",
    },
    {
      id: 3,
      name: parent3,
      box1: "#FFFAEB",
      box2: "#FEF1C7",
    },
    {
      id: 4,
      name: parent4,
      box1: "#F0FAFF",
      box2: "#DFF4FF",
    },
  ];
  const [fade, setFade] = useState(true);
  useEffect(() => {
    const interval = setInterval(() => {
      setFade(false);
      setTimeout(() => {
        setCurrentImage((prevImage) => (prevImage + 1) % images.length);
        setFade(true);
      }, 1000); // Duration for the dissolve effect
    }, 5000); // Duration for how long each image is shown

    return () => clearInterval(interval);
  }, [images.length]);
  console.log(fade);
  return (
    <>
      <Header />
      <div className=" w-full py-[32px] justify-center items-center flex">
        <div className="lg:w-[85%] 100:w-[85%] 100:flex-col xl:flex-row h-fit flex justify-center items-center gap-[64px] ">
          <div className=" flex flex-col 100:w-full md:justify-center md:items-center   lg:w-[40%] 1xl:w-[450px]  100:text-center xl:items-start 100:justify-center xl:justify-start gap-[24px]">
            <section className="flex xl:text-left w-full 100:items-center 100:text-center flex-col xl:items-start 100:justify-center xl:justify-start gap-[24px]">
              <div className=" flex flex-col w-full   gap-[8px]">
                <h1 className=" font-playfair font-semibold leading-[85px] text-[#3C3B3E] text-[64px]">Welcome to </h1>
                <div className=" 100:w-full xl:justify-start 100:justify-center flex 100:items-center xl:items-start xl:w-[350px] ">
                  <img className=" 100:w-[80%] phone:w-[70%] xl:w-[90%]" src={logo} alt="" />
                </div>
              </div>
              <p className=" 100:w-full  phone:w-[400px]   font-openSans text-[#6B6A70] font-normal text-[20px]">Your Ultimate Destination for Pregnancy, Baby Care, and Parenting Tips. Explore Products, Get Expert Advice, and Join Our Community</p>
            </section>
            <section className=" whitespace-nowrap flex gap-[10px] 100:w-full md:w-fit 100:flex-col-reverse phone:flex-row 100:justify-center xl:justify-start items-center">
              <Link onClick={() => window.scrollTo(0, 0)} className=" 100:w-full phone:w-fit" to="/expert-advice">
                <div className=" 100:w-full phone:w-fit flex justify-center items-center bg-[#FFF6ED] border border-[#FE8734] px-[32px] font-openSans font-semibold text-[20px] py-[8px] text-[#6B6A70] h-fit rounded-[20px]  ">Learn More</div>
              </Link>
              <Link onClick={() => window.scrollTo(0, 0)} className=" 100:w-full phone:w-fit" to="/options">
                <div className=" 100:w-full phone:w-fit flex justify-center items-center bg-[#FE8734] px-[32px] font-openSans font-semibold text-[20px] text-[#F5F5F6] py-[8px] h-fit rounded-[20px]  ">Register Now</div>
              </Link>
            </section>
          </div>
          <div className="   100:w-[85%]   relative 100:h-[300px] md:h-[350px] lg:w-[350px] lg:h-[400px] 1xl:w-[450px]  1xl:h-[400px]  rounded-[8px]">
            {/* <div
              className=" w-full h-full absolute  z-[30]  rounded-[8px]"
              style={{
                backgroundImage: `url(${images[currentImage].name})`,
                backgroundSize: "cover",
                transition: "opacity 1s",
                backgroundPosition: "center",
                opacity: fade ? 1 : 0,
              }}
            ></div> */}
            <iframe
              className="w-full h-full rounded-lg"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              allowFullScreen
              src="https://www.youtube.com/embed/BaDg33rTzQM?si=-oqbPU9VGPIEoOy0&autoplay=1&loop=1&playlist=BaDg33rTzQM&mute=1"
            />

            {/* <iframe
              className="w-full h-full rounded-lg"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              allowFullScreen
              src="https://www.youtube.com/embed/BaDg33rTzQM?autoplay=1&mute=1&si=-oqbPU9VGPIEoOy0"
            /> */}
            {/* <iframe
              className="w-full  rounded-lg  h-full"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen
              src="https://www.youtube.com/embed/BaDg33rTzQM?si=-oqbPU9VGPIEoOy0"
            /> */}
            {/* <div
              className=" w-full h-full z-[20] absolute rounded-[8px]"
              style={{
                backgroundColor: images[currentImage].box2,
                bottom: "-30px", // Adjust positioning as needed
                left: "30px",
                transition: "background-color 1s",
              }}
            ></div>
            <div
              className=" w-full h-full z-[10] rounded-[8px]"
              style={{
                backgroundColor: images[currentImage].box1,

                position: "absolute",
                bottom: "-50px", // Adjust positioning as needed
                right: "-50px",
                transition: "background-color 1s",
              }}
            ></div> */}
          </div>
        </div>
      </div>
      <LatestNews />
      <UpcomingEvent />
      <FeaturedProducts />
      <Resources />
      <Stories />
      <Founder />
      <NewsLetter />
      <Footer />
      {showButton && (
        <>
          <div onClick={scrollToTop} className="h-10 shadow z-40 100:right-[40px] top-[80vh] lg:right-[80px] p-2.5 cursor-pointer fixed bg-[#dff4ff] rounded-[100px] justify-center items-center gap-2.5 inline-flex">
            <img src={up} alt="arrow" />
          </div>
        </>
      )}
    </>
  );
}

export default Home;
