import SideBar from "./SideBar";

function Dashboard() {
  return (
    <>
      <div className="bg-[#f5f5f6] w-full flex justify-between items-start">
        <SideBar />
      </div>
    </>
  );
}

export default Dashboard;
