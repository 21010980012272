import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Header from "../Header";
import hero from "../../Images/photos/eventsHeader.png";
// import love from "../../Images/svg/love.svg";
import calendar from "../../Images/svg/calendar.svg";
import event1 from "../../Images/photos/event1.png";
import event2 from "../../Images/photos/event2.jpeg";
import event3 from "../../Images/photos/event3.png";
import event4 from "../../Images/photos/event4.png";
import event5 from "../../Images/photos/event5.png";
import event6 from "../../Images/photos/event6.png";
import event7 from "../../Images/photos/event7.png";
import event8 from "../../Images/photos/event8.png";
import event9 from "../../Images/photos/event9.png";
import event10 from "../../Images/photos/shower.png";
import NewsLetter from "./NavbarComponents/NewsLetter";
import Footer from "./NavbarComponents/Footer";
import up from "../../Images/svg/arrowUp.svg";

function Events() {
  const [showButton, setShowButton] = useState(false);
  const calculateDaysLeft = () => {
    const difference = +new Date("2024-11-16") - +new Date();
    return Math.floor(difference / (1000 * 60 * 60 * 24));
  };

  const [daysLeft, setDaysLeft] = useState(calculateDaysLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setDaysLeft(calculateDaysLeft());
    }, 86400000); // Update every day

    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 600) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // This makes the scroll smooth
    });
  };
  const box = [
    {
      id: 1,
      picture: event1,
      time: daysLeft > 0 ? `${daysLeft} Days to go` : "Event has passed!",
      title: "Doors Open and Music",
      description:
        "Join us for a vibrant baby shower extravaganza! Explore a diverse selection of vendors offering baby essentials, clothing, toys, and more—all set to lively music. It's the perfect place to find everything you need for your little one.",
      venue: "La Madison Place Oniru Lekki",
      actualTime: "9am to 10 am",
      date: "Saturday, November 16th, 2024",
      speaker: "N/A",
    },
    {
      id: 2,
      picture: event2,
      time: daysLeft > 0 ? `${daysLeft} Days to go` : "Event has passed!",
      title: "Opening Remarks by Dr. Renner: The Baby Convention and the Power of Community",
      description: "Gain valuable insights into how community and collaboration play a crucial role in promoting children's health and well-being.",
      venue: "La Madison Place Oniru Lekki",
      actualTime: "10 am to 10.15 am ",
      date: "Saturday, November 16th, 2024",
      speaker: "Dr. Renner",
    },
    {
      id: 3,
      picture: event3,
      time: daysLeft > 0 ? `${daysLeft} Days to go` : "Event has passed!",
      title: "Key Note ",
      description: "Discover insights on the impact of community and collaboration in promoting children’s health and well-being.",
      venue: "La Madison Place Oniru Lekki",
      actualTime: "10.20 am to 11.00 am",
      date: "Saturday, November 16th, 2024",
      speaker: "Nimi Akinkugbe or Dr. Zainab Shinkafi BAgudu",
    },

    {
      id: 4,
      picture: event4,
      time: daysLeft > 0 ? `${daysLeft} Days to go` : "Event has passed!",
      title: "Q and A ",
      description: "Join our informative workshop to learn about the safe and beneficial use of essential oils for newborns and infants. Get your questions answered by experts!",
      venue: "La Madison Place Oniru Lekki",
      actualTime: "11.00 am to 11. 15 am",
      date: "Saturday, November 16th, 2024",
      speaker: "",
    },
    {
      id: 5,
      picture: event5,
      time: daysLeft > 0 ? `${daysLeft} Days to go` : "Event has passed!",
      title: "Activity",
      description: "Get ready for a fun-filled baby shower extravaganza! Explore a wide variety of vendors offering baby essentials, clothing, toys, and more.",
      venue: "La Madison Place Oniru Lekki",
      actualTime: "11.15 am to 12.00pm ",
      date: "Saturday, November 16th, 2024",
      speaker: "Toddler Musical  ",
    },
    {
      id: 6,
      picture: event2,
      time: daysLeft > 0 ? `${daysLeft} Days to go` : "Event has passed!",
      title: "Master Class 1",
      description: "Introduction to Semi-Solids: Learn when and how to introduce semi-solid foods to your baby in this informative session.",
      venue: "La Madison Place Oniru Lekki",
      actualTime: "12.00pm to 1.00 pm",
      date: "Saturday, November 16th, 2024",
      speaker: "Dr. Renner  ",
    },
    {
      id: 7,
      picture: event7,
      time: daysLeft > 0 ? `${daysLeft} Days to go` : "Event has passed!",
      title: "Panel Session 1",
      description: "Join our expert panel as they discuss the safe and effective use of essential oils for newborns and infants. Gain valuable insights and tips from specialists in this informative session.",
      venue: "La Madison Place Oniru Lekki",
      actualTime: "1.00 pm to 2.00 pm",
      date: "Saturday, November 16th, 2024",
      speaker: "Linda Ejiofor, Nicole Chikwe, Toyosi Etim Effiong , Fab Mum  ",
    },
    {
      id: 8,
      picture: event8,
      time: daysLeft > 0 ? `${daysLeft} Days to go` : "Event has passed!",
      title: "Master Class 2 ",
      description: "Join us for a fun-filled baby shower event! Discover a variety of vendors offering baby essentials, clothing, toys, and more.",
      venue: "La Madison Place Oniru Lekki",
      actualTime: "2.00 pm to 3.00 pm",
      date: "Saturday, November 16th, 2024",
      speaker: "Dr Cole Adeife",
    },
    {
      id: 9,
      picture: event9,
      time: daysLeft > 0 ? `${daysLeft} Days to go` : "Event has passed!",
      title: "Panel Session 2: Autism in Kids",
      description: "Join us as experts discuss insights and strategies for supporting children with autism. Don’t miss this opportunity to learn and engage on this important topic.",
      venue: "La Madison Place Oniru Lekki",
      actualTime: "3.00 pm to 4.00pm",
      date: "Saturday, November 16th, 2024",
      speaker: "Patrick speech, Bode Abifarin, ",
    },
    {
      id: 10,
      picture: event10,
      time: daysLeft > 0 ? `${daysLeft} Days to go` : "Event has passed!",
      title: "Activity and Closing: Baby/Toddler Massage",
      description: "Experience the calming benefits of baby and toddler massage with our expert-led session. A soothing end to a delightful event!",
      venue: "La Madison Place Oniru Lekki",
      actualTime: "4 pm to 5 pm",
      date: "Saturday, November 16th, 2024",
      speaker: "N/A",
    },
    {
      id: 11,
      picture: event4,
      time: daysLeft > 0 ? `${daysLeft + 1} Days to go` : "Event has passed!",
      title: "Doors Open and Music",
      description: "Experience the calming benefits of baby and toddler massage with our expert-led session. A soothing end to a delightful event!",
      venue: "La Madison Place Oniru Lekki",
      actualTime: "9am to 10 am",
      date: "Saturday, November 17th, 2024",
      speaker: "N/A",
    },
    {
      id: 12,
      picture: event1,
      time: daysLeft > 0 ? `${daysLeft} Days to go` : "Event has passed!",
      title: "Activity: Karaoke",
      description: "Show off your singing skills and have a blast with our karaoke session! Join in the fun and enjoy some musical entertainment.",
      venue: "La Madison Place Oniru Lekki",
      actualTime: "10 am to 11.00 am ",
      date: "Saturday, November 17th, 2024",
      speaker: "N/A",
    },
    {
      id: 13,
      picture: event2,
      time: daysLeft > 0 ? `${daysLeft + 1} Days to go` : "Event has passed!",
      title: "Activity: Crawl Race",
      description: "Join us for an exciting and fun-filled crawl race! Watch little ones show off their crawling skills and cheer them on in this playful competition.",
      venue: "La Madison Place Oniru Lekki",
      actualTime: "11.00 am to 12.00 pm ",
      date: "Saturday, November 17th, 2024",
      speaker: "N/A",
    },
    {
      id: 14,
      picture: event5,
      time: daysLeft > 0 ? `${daysLeft + 1} Days to go` : "Event has passed!",
      title: "Panel Session 1: Fatherhood 101",
      description: "Join us for an engaging discussion on the essentials of fatherhood. Gain valuable insights and tips to support and enhance your journey as a dad.",
      venue: "La Madison Place Oniru Lekki",
      actualTime: "12.00 pm to 1.00 pm ",
      date: "Saturday, November 17th, 2024",
      speaker: "Ibrahim Suleiman, Banky W, Daniel Effiong",
    },
    {
      id: 15,
      picture: event6,
      time: daysLeft > 0 ? `${daysLeft + 1} Days to go` : "Event has passed!",
      title: "Master Class 3: Feeding Toddlers ",
      description: "Discover effective strategies and practical tips for feeding toddlers. Learn how to create balanced meals and handle common feeding challenges in this informative session. ",
      venue: "La Madison Place Oniru Lekki",
      actualTime: "1.00 pm to 2.00 pm ",
      date: "Saturday, November 17th, 2024",
      speaker: "Sisi Yemmie",
    },
    {
      id: 16,
      picture: event7,
      time: daysLeft > 0 ? `${daysLeft + 1} Days to go` : "Event has passed!",
      title: "Panel Session 2: Screen Time ",
      description: "Explore the impact of screen time on children and gain insights into managing digital exposure. Join us for a discussion on setting healthy screen time limits and fostering balanced habits.",
      venue: "La Madison Place Oniru Lekki",
      actualTime: "2.00 pm to 3.00 pm ",
      date: "Saturday, November 17th, 2024",
      speaker: "Sisi Yemmie",
    },
    {
      id: 17,
      picture: event1,
      time: daysLeft > 0 ? `${daysLeft + 1} Days to go` : "Event has passed!",
      title: "Master Class 4: Breast Feeding ",
      description: "Gain valuable knowledge about breastfeeding, including techniques, benefits, and common challenges. Join us to enhance your breastfeeding journey with expert guidance and support. ",
      venue: "La Madison Place Oniru Lekki",
      actualTime: "3.00pm to 4.00 pm ",
      date: "Saturday, November 17th, 2024",
      speaker: "The Milk Booster ",
    },
    {
      id: 18,
      picture: event9,
      time: daysLeft > 0 ? `${daysLeft + 1} Days to go` : "Event has passed!",
      title: "Activity and Closing: Raffle Draw ",
      description: "Wrap up the event with our exciting raffle draw! Have a chance to win fantastic prizes and celebrate the day with a final touch of fun. ",
      venue: "La Madison Place Oniru Lekki",
      actualTime: "4.00 pm to 5.00 pm ",
      date: "Saturday, November 17th, 2024",
      speaker: "N/A",
    },
  ];
  return (
    <>
      <Header />
      <div className="w-full h-fit gap-[36px] pt-[48px]  pb-[96px] 100:px-[32px] lg:px-[64px] flex-col flex justify-center items-center">
        <div className=" 100:w-full lg:w-[90vh] h-fit md:px-[24px]  pb-[40px] flex-col justify-center text-center items-center gap-[78px] flex">
          <div className=" w-full flex flex-col justify-center items-center ">
            <div
              className="rounded-[24px] md:w-[100vh] lg:w-[900px] xl:w-[1100px] 1xl:w-[1264px]  w-full 100:h-[40vh] lg:h-[481px] "
              style={{ backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%),url(${hero})`, backgroundSize: "cover", backgroundRepeat: "no-repeat" }}
            />
            <div className="lg:w-[699px] 100:w-[300px] phone:w-[400px] 100:mt-[-78px] md:mt-[-105px] 100:text-[40px] md:text-[64px]  font-playfair font-bold text-center">
              <span className="text-[#f5f5f6] ">Don't Miss Our</span> Upcoming Events
            </div>
          </div>
          <div className="  flex h-fit 1xl:w-[1340px] lg:w-[900px]  justify-center items-start 100:w-full flex-wrap lg:gap-8 100:gap-10 ">
            {box.map((item) => (
              <div key={item.id} className=" flex flex-col items-center justify-center  h-fit p-[10px] 100:w-full sm:w-[420px]   ">
                <div style={{ backgroundImage: `url(${item.picture})`, backgroundSize: "cover" }} className=" w-full h-[266.67px] rounded-[20px]">
                  <div className=" p-[15px] w-full flex justify-start items-start">
                    <h5 className=" h-6    px-2 py-1 bg-[#fff6ed] rounded-[20px] border border-[#ffb472] justify-end items-center gap-1 inline-flex text-[#6b6a70] text-xs font-semibold  font-openSans">{item.time}</h5>
                  </div>
                </div>
                <div className="sm:w-[380px] 100:w-[85%] mt-[-64px] h-fit 100:px-[16px] 100:py-[24px] md:p-6 bg-[#fff6ed] rounded-[20px] flex-col justify-start items-start gap-4 inline-flex">
                  <div className=" flex-col text-left justify-start items-start gap-[10px] inline-flex">
                    <h3 className="text-[#3c3b3e] text-xl font-bold font-openSans">{item.title}</h3>
                    <p className="text-[#6b6a70] text-sm font-normal font-playfair italic">{item.description}</p>
                    <div className="bg-[#FAE9F2] w-fit h-fit rounded-[20px] gap-[4px] px-[8px] py-[4px] flex justify-center items-center">
                      <img src={calendar} alt="calenadr" />

                      <p className="text-[#e05493] text-[12px] font-semibold font-openSans">{item.date}</p>
                    </div>
                    <h4 className="text-[#6b6a70] text-xs font-medium font-openSans">
                      Time: <span className="text-[#3c3b3e] font-semibold">{item.actualTime}</span>
                    </h4>{" "}
                    <h4 className="text-[#6b6a70] flex justify-start items-start gap-[4px] text-xs font-medium font-openSans">
                      Speaker: <span className="text-[#3c3b3e] font-semibold">{item.speaker}</span>
                    </h4>
                    <h4 className="text-[#6b6a70] text-xs font-medium font-openSans">
                      Venue: <span className="text-[#3c3b3e] font-semibold">{item.venue}</span>
                    </h4>{" "}
                  </div>
                  <div className=" w-full justify-end items-center inline-flex">
                    <Link onClick={() => window.scrollTo(0, 0)} to="/options">
                      <div className="w-[77px] h-[23px] px-8 py-1 bg-[#fe8734] rounded-[20px] justify-center items-center inline-flex text-[#f5f5f6] text-xs font-medium font-openSans">Register</div>
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <NewsLetter />
      <Footer />
      {showButton && (
        <>
          <div onClick={scrollToTop} className="h-10 shadow z-40 100:right-[40px] top-[80vh] lg:right-[80px] p-2.5 cursor-pointer fixed bg-[#dff4ff] rounded-[100px] justify-center items-center gap-2.5 inline-flex">
            <img src={up} alt="arrow" />
          </div>
        </>
      )}
    </>
  );
}

export default Events;
