import { useState } from "react";
import a from "../../../Images/photos/blue1.png";
import b from "../../../Images/photos/blue2.png";
import c from "../../../Images/photos/blue3.png";
import d from "../../../Images/photos/pink1.png";
import e from "../../../Images/photos/pink2.png";
import f from "../../../Images/photos/pink3.png";
import g from "../../../Images/photos/yellow1.png";
import h from "../../../Images/photos/yellow2.png";
import i from "../../../Images/photos/yellow3.png";
import j from "../../../Images/photos/orange1.png";
import k from "../../../Images/photos/orange2.png";
import l from "../../../Images/photos/orange3.png";
import m from "../../../Images/photos/blu1.png";
import n from "../../../Images/photos/blu2.png";
import o from "../../../Images/photos/blu3.png";
import p from "../../../Images/photos/pin1.png";
import q from "../../../Images/photos/pin2.png";
import r from "../../../Images/photos/pin3.png";
import s from "../../../Images/photos/yello1.png";
import t from "../../../Images/photos/yello2.png";
import u from "../../../Images/photos/yello3.png";
import v from "../../../Images/photos/orang1.png";
import w from "../../../Images/photos/orang2.png";
import x from "../../../Images/photos/orang3.png";
import { Link } from "react-router-dom";

function FeaturedProducts() {
  const [activeCategory, setActiveCategory] = useState("nursery");
  const blue1 = [
    {
      picture: a,
      product: "Deluxe Crib",
      price: "₦299.99",
    },
    {
      picture: b,
      product: "Organic Cotton Crib Sheets",
      price: "₦299.99",
    },
    {
      picture: c,
      product: "Nursery Rhymes",
      price: "₦299.99",
    },
    {
      picture: x,
      product: "Baby Rhymes",
      price: "₦299.99",
    },
  ];
  const pink1 = [
    {
      picture: d,
      product: "Wooden Building Blocks Set",
      price: "₦299.99",
    },
    {
      picture: e,
      product: "Soft Plush Toy",
      price: "₦299.99",
    },
    {
      picture: f,
      product: "Toys",
      price: "₦299.99",
    },
    {
      picture: t,
      product: "Push Toys",
      price: "₦299.99",
    },
  ];
  const yellow1 = [
    {
      picture: g,
      product: "Wooden Building Blocks Set",
      price: "₦299.99",
    },
    {
      picture: h,
      product: "Soft Plush Toy",
      price: "₦299.99",
    },
    {
      picture: i,
      product: "Toys",
      price: "₦299.99",
    },
    {
      picture: a,
      product: "Toys",
      price: "₦299.99",
    },
  ];
  const orange1 = [
    {
      picture: j,
      product: "Wooden Building Blocks Set",
      price: "₦299.99",
    },
    {
      picture: k,
      product: "Soft Plush Toy",
      price: "₦299.99",
    },
    {
      picture: l,
      product: "Toys",
      price: "₦299.99",
    },
    {
      picture: u,
      product: "Toys",
      price: "₦299.99",
    },
  ];
  const blu1 = [
    {
      picture: m,
      product: "Deluxe Crib",
      price: "₦299.99",
    },
    {
      picture: n,
      product: "Organic Cotton Crib Sheets",
      price: "₦299.99",
    },
    {
      picture: o,
      product: "Nursery Rhymes",
      price: "₦299.99",
    },
    {
      picture: e,
      product: "Nursery Rhymes",
      price: "₦299.99",
    },
  ];
  const pin1 = [
    {
      picture: p,
      product: "Wooden Building Blocks Set",
      price: "₦299.99",
    },
    {
      picture: q,
      product: "Soft Plush Toy",
      price: "₦299.99",
    },
    {
      picture: r,
      product: "Toys",
      price: "₦299.99",
    },
    {
      picture: b,
      product: "Toys",
      price: "₦299.99",
    },
  ];
  const yello1 = [
    {
      picture: s,
      product: "Wooden Building Blocks Set",
      price: "₦299.99",
    },
    {
      picture: t,
      product: "Soft Plush Toy",
      price: "₦299.99",
    },
    {
      picture: u,
      product: "Toys",
      price: "₦299.99",
    },
    {
      picture: e,
      product: "Toys",
      price: "₦299.99",
    },
  ];
  const orang1 = [
    {
      picture: v,
      product: "Wooden Building Blocks Set",
      price: "₦299.99",
    },
    {
      picture: w,
      product: "Soft Plush Toy",
      price: "₦299.99",
    },
    {
      picture: x,
      product: "Toys",
      price: "₦299.99",
    },
    {
      picture: a,
      product: "Toys",
      price: "₦299.99",
    },
  ];

  const toggleCategory = (category) => {
    setActiveCategory(category);
  };

  return (
    <>
      <div className="w-full h-fit gap-[60px] bg-[#F0FAFF] py-[96px] 100:px-[32px] lg:px-[64px] flex-col flex justify-center items-center">
        <div className=" flex-col self-stretch justify-center md:mt-[-16px] text-center items-center gap-[10px] inline-flex">
          <h3 className="text-[#3c3b3e] text-[32px] font-semibold font-playfair">Featured Products for You and Your Baby</h3>
          <p className=" text-center text-[#6b6a70] text-lg font-normal font-openSans">Handpicked Products for Expectant Parents and Young Children</p>
        </div>
        <div className=" flex flex-col gap-[48px] 2xl:w-[1300px] w-full items-center justify-center">
          <div className=" py-[10px] flex text-[#6B6A70]  text-base font-semibold font-openSans items-start gap-[48px] justify-start whitespace-nowrap overflow-x-scroll overflow-y-hidden 100:w-full lg:w-fit">
            <button className={`h-[22px] ${activeCategory === "nursery" ? "pb-[4px] border-b border-b-[#fe8734] text-[#fe8734]" : ""}`} onClick={() => toggleCategory("nursery")}>
              Nursery{" "}
            </button>
            <button className={`h-[22px] ${activeCategory === "toys" ? "pb-[4px] border-b border-b-[#fe8734] text-[#fe8734]" : ""}`} onClick={() => toggleCategory("toys")}>
              {" "}
              Toys{" "}
            </button>
            <button className={`h-[22px] ${activeCategory === "clothing" ? "pb-[4px] border-b border-b-[#fe8734] text-[#fe8734]" : ""}`} onClick={() => toggleCategory("clothing")}>
              {" "}
              Clothing{" "}
            </button>
            <button className={`h-[22px] ${activeCategory === "feeding" ? "pb-[4px] border-b border-b-[#fe8734] text-[#fe8734]" : ""}`} onClick={() => toggleCategory("feeding")}>
              {" "}
              Feeding{" "}
            </button>{" "}
            <button className={`h-[22px] ${activeCategory === "health-safety" ? "pb-[4px] border-b border-b-[#fe8734] text-[#fe8734]" : ""}`} onClick={() => toggleCategory("health-safety")}>
              {" "}
              Health & Safety{" "}
            </button>{" "}
            <button className={`h-[22px] ${activeCategory === "travel-gear" ? "pb-[4px] border-b border-b-[#fe8734] text-[#fe8734]" : ""}`} onClick={() => toggleCategory("travel-gear")}>
              {" "}
              Travel Gear{" "}
            </button>{" "}
            <button className={`h-[22px] ${activeCategory === "bath-potty" ? "pb-[4px] border-b border-b-[#fe8734] text-[#fe8734]" : ""}`} onClick={() => toggleCategory("bath-potty")}>
              {" "}
              Bath & Potty{" "}
            </button>{" "}
            <button className={`h-[22px] ${activeCategory === "books-media" ? "pb-[4px] border-b border-b-[#fe8734] text-[#fe8734]" : ""}`} onClick={() => toggleCategory("books-media")}>
              {" "}
              Books & Media{" "}
            </button>
          </div>
          {/* <div className="overflow-x-scroll pb-[16px] 100:justify-start 100:items-start 1xl:justify-center flex 1xl:items-center w-full"> */}
          {/* <div className="w-fit flex justify-start items-start gap-[32px] px-[8px] pt-[8px] pb-[16px]"> */}
          <div className=" flex h-fit xl:flex-nowrap flex-1  100:flex-wrap  justify-center items-start w-full lg:gap-8 100:gap-10 ">
            {activeCategory === "nursery" && (
              <>
                {blue1.map((item) => (
                  <div className="  100:w-[32vh] flex-grow 100:h-fit phone:w-[35vh] lg:w-[410.67px]  lg:h-[321px] px-2.5 pt-2.5 pb-4 bg-[#dff4ff] rounded-2xl shadow-custom border-2 border-[#41cffe] flex-col justify-start items-center gap-6 inline-flex">
                    <div className=" w-full 100:h-[25vh] lg:h-[244px] rounded-[20px]" style={{ backgroundImage: `url(${item.picture})`, backgroundSize: "cover", backgroundRepeat: "no-repeat" }}></div>
                    <div className=" w-full items-center flex justify-between">
                      <h3 className=" text-[18px] font-openSans font-semibold text-[#3C3B3E] ">{item.product}</h3>
                      <p className=" text-[14px] font-openSans font-semibold text-[#6B6A70] ">{item.price}</p>
                    </div>
                  </div>
                ))}
              </>
            )}
            {activeCategory === "toys" && (
              <>
                {pink1.map((item) => (
                  <div className="100:w-[32vh] flex-grow phone:w-[35vh] lg:w-[410.67px] 100:h-[30vh] lg:h-[321px] px-2.5 pt-2.5 pb-4 bg-[#fae9f2] rounded-2xl shadow-customPink border-2 border-[#EA79AF] flex-col justify-start items-center gap-6 inline-flex">
                    <div className=" w-full 100:h-[25vh] lg:h-[244px] rounded-[20px]" style={{ backgroundImage: `url(${item.picture})`, backgroundSize: "cover", backgroundRepeat: "no-repeat" }}></div>
                    <div className=" w-full items-center flex justify-between">
                      <h3 className=" text-[18px] font-openSans font-semibold text-[#3C3B3E] ">{item.product}</h3>
                      <p className=" text-[14px] font-openSans font-semibold text-[#6B6A70] ">{item.price}</p>
                    </div>
                  </div>
                ))}
              </>
            )}
            {activeCategory === "clothing" && (
              <>
                {yellow1.map((item) => (
                  <div className="100:w-[32vh] flex-grow phone:w-[35vh] lg:w-[410.67px] 100:h-[30vh] lg:h-[321px] px-2.5 pt-2.5 pb-4  bg-[#FEF1C7] rounded-2xl shadow-customYellow border-2 border-[#FBB724] flex-col justify-start items-center gap-6 inline-flex">
                    {/* <div className="1100:w-[32vh] phone:w-[35vh] lg:w-[410.67px] 100:h-[30vh] lg:h-[321px] px-2.5 pt-2.5 pb-4 bg-[#FEF1C7] rounded-2xl shadow-customYellow border-2 border-[#FBB724] flex-col justify-start items-center gap-6 inline-flex"> */}
                    {/* <div className=" w-full 100:h-[25vh] lg:h-[244px] rounded-[20px]" style={{ backgroundImage: `url(${item.picture})`, backgroundSize: "cover", backgroundRepeat: "no-repeat" }}></div> */}
                    <div className=" w-full 100:h-[25vh] lg:h-[244px] rounded-[20px]" style={{ backgroundImage: `url(${item.picture})`, backgroundSize: "cover", backgroundRepeat: "no-repeat" }}></div>

                    <div className=" w-full items-center flex justify-between">
                      <h3 className=" text-[18px] font-openSans font-semibold text-[#3C3B3E] ">{item.product}</h3>
                      <p className=" text-[14px] font-openSans font-semibold text-[#6B6A70] ">{item.price}</p>
                    </div>
                  </div>
                ))}
              </>
            )}
            {activeCategory === "feeding" && (
              <>
                {orange1.map((item) => (
                  <div className="100:w-[32vh] flex-grow phone:w-[35vh] lg:w-[410.67px] 100:h-[30vh] lg:h-[321px] px-2.5 pt-2.5 pb-4  bg-[#FFEBD4] rounded-2xl shadow-customOrange border-2 border-[#FE8734] flex-col justify-start items-center gap-6 inline-flex">
                    <div className=" w-full 100:h-[25vh] lg:h-[244px] rounded-[20px]" style={{ backgroundImage: `url(${item.picture})`, backgroundSize: "cover", backgroundRepeat: "no-repeat" }}></div>
                    <div className=" w-full items-center flex justify-between">
                      <h3 className=" text-[18px] font-openSans font-semibold text-[#3C3B3E] ">{item.product}</h3>
                      <p className=" text-[14px] font-openSans font-semibold text-[#6B6A70] ">{item.price}</p>
                    </div>
                  </div>
                ))}
              </>
            )}
            {activeCategory === "health-safety" && (
              <>
                {blu1.map((item) => (
                  <div className="100:w-[32vh] flex-grow phone:w-[35vh] lg:w-[410.67px] 100:h-[30vh] lg:h-[321px] px-2.5 pt-2.5 pb-4  bg-[#dff4ff] rounded-2xl shadow-custom border-2 border-[#41cffe] flex-col justify-start items-center gap-6 inline-flex">
                    <div className=" w-full 100:h-[25vh] lg:h-[244px] rounded-[20px]" style={{ backgroundImage: `url(${item.picture})`, backgroundSize: "cover", backgroundRepeat: "no-repeat" }}></div>
                    <div className=" w-full items-center flex justify-between">
                      <h3 className=" text-[18px] font-openSans font-semibold text-[#3C3B3E] ">{item.product}</h3>
                      <p className=" text-[14px] font-openSans font-semibold text-[#6B6A70] ">{item.price}</p>
                    </div>
                  </div>
                ))}
              </>
            )}
            {activeCategory === "travel-gear" && (
              <>
                {pin1.map((item) => (
                  <div className="100:w-[32vh] flex-grow phone:w-[35vh] lg:w-[410.67px] 100:h-[30vh] lg:h-[321px] px-2.5 pt-2.5 pb-4 bg-[#fae9f2] rounded-2xl shadow-customPink border-2 border-[#EA79AF] flex-col justify-start items-center gap-6 inline-flex">
                    <div className=" w-full 100:h-[25vh] lg:h-[244px] rounded-[20px]" style={{ backgroundImage: `url(${item.picture})`, backgroundSize: "cover", backgroundRepeat: "no-repeat" }}></div>
                    <div className=" w-full items-center flex justify-between">
                      <h3 className=" text-[18px] font-openSans font-semibold text-[#3C3B3E] ">{item.product}</h3>
                      <p className=" text-[14px] font-openSans font-semibold text-[#6B6A70] ">{item.price}</p>
                    </div>
                  </div>
                ))}
              </>
            )}
            {activeCategory === "bath-potty" && (
              <>
                {yello1.map((item) => (
                  <div className="100:w-[32vh] flex-grow phone:w-[35vh] lg:w-[410.67px] 100:h-[30vh] lg:h-[321px] px-2.5 pt-2.5 pb-4  bg-[#FEF1C7] rounded-2xl shadow-customYellow border-2 border-[#FBB724] flex-col justify-start items-center gap-6 inline-flex">
                    {/* <div className="1100:w-[32vh] phone:w-[35vh] lg:w-[410.67px] 100:h-[30vh] lg:h-[321px] px-2.5 pt-2.5 pb-4 bg-[#FEF1C7] rounded-2xl shadow-customYellow border-2 border-[#FBB724] flex-col justify-start items-center gap-6 inline-flex"> */}
                    {/* <div className=" w-full 100:h-[25vh] lg:h-[244px] rounded-[20px]" style={{ backgroundImage: `url(${item.picture})`, backgroundSize: "cover", backgroundRepeat: "no-repeat" }}></div> */}
                    <div className=" w-full 100:h-[25vh] lg:h-[244px] rounded-[20px]" style={{ backgroundImage: `url(${item.picture})`, backgroundSize: "cover", backgroundRepeat: "no-repeat" }}></div>
                    <div className=" w-full items-center flex justify-between">
                      <h3 className=" text-[18px] font-openSans font-semibold text-[#3C3B3E] ">{item.product}</h3>
                      <p className=" text-[14px] font-openSans font-semibold text-[#6B6A70] ">{item.price}</p>
                    </div>
                  </div>
                ))}
              </>
            )}
            {activeCategory === "books-media" && (
              <>
                {orang1.map((item) => (
                  <div className="100:w-[32vh] flex-grow phone:w-[35vh] lg:w-[410.67px] 100:h-[30vh] lg:h-[321px] px-2.5 pt-2.5 pb-4  bg-[#FFEBD4] rounded-2xl shadow-customOrange border-2 border-[#FE8734] flex-col justify-start items-center gap-6 inline-flex">
                    <div className=" w-full 100:h-[25vh] lg:h-[244px] rounded-[20px]" style={{ backgroundImage: `url(${item.picture})`, backgroundSize: "cover", backgroundRepeat: "no-repeat" }}></div>
                    <div className=" w-full items-center flex justify-between">
                      <h3 className=" text-[18px] font-openSans font-semibold text-[#3C3B3E] ">{item.product}</h3>
                      <p className=" text-[14px] font-openSans font-semibold text-[#6B6A70] ">{item.price}</p>
                    </div>
                  </div>
                ))}
              </>
            )}
            {/* </div> */}
          </div>
          <Link onClick={() => window.scrollTo(0, 0)} to="/products">
            <div className="h-[43px] font-openSans px-8 py-2 bg-[#fff6ed] text-[#6b6a70] text-xl font-semibold gap-2  rounded-[20px] border border-[#fe8734] justify-center items-center inline-flex">See All</div>
          </Link>
        </div>
      </div>
    </>
  );
}

export default FeaturedProducts;
