import logo from "../Images/photos/ThebabyConventionColorLogo.png";
import arrow from "../Images/svg/arrowleft.svg";
import { Link } from "react-router-dom";

function VendorInstruction() {
  return (
    <>
      <div className=" w-full flex flex-col justify-start items-center px-[10px] py-[40px] ">
        <div className=" h-[100px] bg-white/40 w-full top-0 left-[40px] 100:pr-[70px] right-[40px] flex fixed justify-between items-center">
          <Link onClick={() => window.scrollTo(0, 0)} to="/options">
            <div className="w-fit flex justify-center items-center gap-2">
              <div>
                <img className="w-full" src={arrow} alt="back" />
              </div>
              <div className="text-[#fe8734] 100:hidden md:block text-base font-semibold font-openSans">Back</div>
            </div>
          </Link>
          <div className=" 100:w-[60px] iphone:w-[80px] iphone:h-[54.89px]">
            <img className="w-full" src={logo} alt="The Baby Convention" />
          </div>
          <div className="w-[120px] 100:hidden md:block"></div>
        </div>
        <div className="h-fit 100:w-[85%]  sm:w-[498px] mt-[130px] p-6 bg-[#fff6ed] rounded-3xl shadow flex-col justify-start items-start gap-2.5 inline-flex">
          <div className="w-full  flex-col justify-start items-start gap-8 inline-flex">
            <div className=" flex-col justify-start items-center gap-2 inline-flex">
              <h2 className="self-stretch text-center text-[#3c3b3e] text-[32px] font-semibold font-playfair">Dear Vendor</h2>
              <p className=" w-[90%] text-center text-[#6b6a70] text-sm font-normal font-openSans">
                We’re excited to have you to become an exhibitor at The Baby Convention! Increase your brand recognition and drive high direct sales and sampling to a targeted audience of affluent new and expectant parents as well as growing
                families.{" "}
              </p>
            </div>
            <div className=" p-2.5 flex-col justify-start items-start gap-3.5 inline-flex">
              <h4 className=" w-full text-[#4e4d51] text-sm font-semibold font-openSans">Here’s how to get started:</h4>
              <ul className=" w-full list-decimal flex flex-col gap-[23px] leading-[22px] text-[#4e4d51] text-sm font-normal font-openSans ">
                <li className=" ">Complete the Vendor Application: Fill out our online application form, providing details about your business and the products/services you offer.</li>
                <li className=" ">Review and Approval: Our team will review your application and contact you within 24 hours to confirm your participation.</li>
                <li className=" ">Payment: A link will be sent to you via email for payment to be made.</li>
                <li className=" ">Confirmation and Logistics: Once payment is received, we'll confirm your booth has been secured and provide you with details for setup.</li>
              </ul>
            </div>
            <div className=" p-2.5 flex-col justify-start items-start gap-3.5 inline-flex">
              <h4 className=" w-full text-[#4e4d51] text-sm font-semibold font-openSans">Booth Prices and Locations:</h4>
              <ul className=" w-full list-decimal flex flex-col gap-[23px] leading-[22px] text-[#4e4d51] text-sm font-normal font-openSans ">
                <li className=" ">
                  Early Bird (Sept 1st - Oct 16th ): <b>₦122,000 Naira</b> for the booths
                </li>
                <li className=" ">
                  ⁠Regular registration: (Oct 17th - Nov 10th ): <b>₦152,500</b> for the booths
                </li>
              </ul>
            </div>
            <Link onClick={() => window.scrollTo(0, 0)} to="/vendor" className="w-full ">
              <div className="w-full h-12 px-8 py-2 bg-[#fe8734] rounded-[20px] justify-center text-[#f5f5f6] text-xl font-semibold font-openSans items-center inline-flex">Proceed</div>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default VendorInstruction;
