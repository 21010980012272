import cancel from "./Images/svg/Cancel.svg";

function PrivacyParticipant({ setPrivacy }) {
  return (
    <>
      {/* <div className=" fixed z-[50] flex justify-center items-center w-full h-full bg-black bg-opacity-40 top-0 left-0">
        <div className={`100:w-[85%] sm:w-[500px]  h-[619px] pb-[40px] p-2.5 gap-[16px] bg-[#f5f5f6] rounded-[20px] flex-col justify-start items-center  inline-flex`}>
          <div className=" pl-[24px] flex justify-between items-center w-full">
            <h3 className="text-[#3c3b3e] text-xl font-semibold font-openSans">Terms and Conditions</h3>

            <div onClick={() => setPrivacy(false)} className="w-12 cursor-pointer h-12  justify-center flex  items-center bg-black/30 rounded-[100px] backdrop-blur-[31.63px]  ">
              <img className=" " src={cancel} alt="Cancel" />
            </div>
          </div>
          <div className=" flex-col flex justify-start gap-[24px] items-center w-full h-full overflow-y-scroll">
            <p className=" w-[90%]  text-left text-[#6b6a70] text-base font-normal font-openSans">
              The exhibitor agrees to comply with all laws, regulations, and guidelines set by the expo organizers and the venue.
              <br /> The exhibitor must ensure their booth is staffed and operational during all expo hours. <br />
              The exhibitor is responsible for the setup, maintenance, and dismantling of their exhibit.
              <br />
              <br /> Booth Assignment Booth assignments are made by the expo organizers and are final. Exhibitors may not sublet, share, or exchange their booth space without prior written consent from the organizers. Payment and Cancellation Full
              payment for the booth space is required by the specified deadline.
              <br />
              <br /> Cancellations must be made in writing. Refunds will be provided based on the cancellation policy outlined by the organizers. Insurance and Liability Exhibitors must carry adequate insurance coverage for their property and
              personnel.
              <br />
              <br /> The expo organizers are not liable for any loss, damage, or injury incurred by the exhibitor or their representatives. Conduct and Compliance Exhibitors must conduct themselves in a professional manner and not engage in any
              activity that disrupts the expo.
              <br />
              <br /> All promotional activities must be confined to the exhibitor’s booth space. Intellectual Property Exhibitors must respect all intellectual property rights and not display or distribute any unauthorized materials.
              <br />
              <br /> Health and Safety Exhibitors must adhere to all health and safety regulations, including fire codes and emergency procedures. Product Safety All baby products displayed must comply with relevant safety standards and regulations.
              <br />
              <br /> Exhibitors must provide clear and accurate information about the safety and usage of their products. Force Majeure The expo organizers are not responsible for any cancellation or disruption of the event due to circumstances
              beyond their control (e.g., natural disasters, pandemics). <br />
              <br />
            </p>{" "}
          </div>
        </div>
      </div> */}
      <div className=" fixed z-[50] flex justify-center items-center w-full h-full bg-black bg-opacity-40 top-0 left-0">
        <div className={`100:w-[85%] sm:w-[500px]  h-[619px] pb-[40px] p-2.5 gap-[16px] bg-[#f5f5f6] rounded-[20px] flex-col justify-start items-center  inline-flex`}>
          <div className=" pl-[24px] flex justify-between items-center w-full">
            <h3 className="text-[#3c3b3e] text-xl font-semibold font-openSans">Masterclass Terms and Conditions</h3>

            <div onClick={() => setPrivacy(false)} className="w-12 cursor-pointer h-12  justify-center flex  items-center bg-black/30 rounded-[100px] backdrop-blur-[31.63px]  ">
              <img className=" " src={cancel} alt="Cancel" />
            </div>
          </div>
          <div className="gap-[24px] flex-col flex justify-start  items-center w-full h-full overflow-y-scroll">
            <ul className=" w-[90%] list-disc flex flex-col gap-[24px]  text-left text-[#6b6a70] text-base font-normal font-openSans">
              <li>
                <b>Registration and Payment: </b> Registration is required and confirmed upon full payment of the masterclass fee.{" "}
              </li>
              <li>
                <b>Masterclass Content:</b> The masterclass will cover selected topics on application and be delivered by experts and professionals in the industry.{" "}
              </li>
              <li>
                <b>Participant Conduct: </b> Participants are expected to conduct themselves in a respectful and professional manner as disruptive behaviors may result in removal from the masterclass. Participants must comply with all relevant laws
                and regulations.{" "}
              </li>
              <li>
                <b>Intellectual Property: </b> TAll materials provided are the intellectual property of the instructor and organizers and may not be reproduced, distributed, or sold without permission.{" "}
              </li>
              <li>
                <b>Event Cancellation:</b> - Upon cancellation by the organizers, participants will receive a full refund or be offered an alternative date.
              </li>
              <li>
                <b>Liability: </b> The organizers are not liable for any losses or injuries incurred by participants. Participants are responsible for their own safety and well-being.The organizers are not liable for any losses or injuries incurred
                by participants. Participants are responsible for their own safety and well-being.
              </li>
              <li>
                <b>Privacy:</b> - he organizers may collect personal information from participants for the purpose of registering and managing the masterclass. All personal information will be handled in accordance with data protection laws.
              </li>

              <p className="text-[#6d6d70] text-base font-normal font-openSans">By registering, you agree to these terms and conditions.</p>
            </ul>{" "}
          </div>
        </div>
      </div>
    </>
  );
}

export default PrivacyParticipant;
