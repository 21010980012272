import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../Images/photos/ThebabyConventionColorLogo.png";
import arrow from "../Images/svg/arrowleft.svg";
import participant1 from "../Images/svg/participantStroke.svg";
import participant2 from "../Images/svg/participantFilled.svg";
import vendor1 from "../Images/svg/vendorStroke.svg";
import vendor2 from "../Images/svg/vendorFilled.svg";
import sponsor1 from "../Images/svg/sponsorStroke.svg";
import sponsor2 from "../Images/svg/sponsorFilled.svg";

const options = [
  {
    id: 1,
    picture1: participant1,
    picture2: participant2,
    title: "Participants",
    description: "Register to attend workshops and master classes.",
    path: "/participant",
    name: "participant",
  },
  {
    id: 2,
    picture1: vendor1,
    picture2: vendor2,
    title: "Vendors",
    description: "Showcase your products and services.",
    path: "/instruction",
    name: "vendor",
  },
  {
    id: 3,
    picture1: sponsor1,
    picture2: sponsor2,
    title: "Sponsors",
    description: "Support the event and explore sponsorship packages.",
    path: "/sponsor",
    name: "sponsor",
  },
];
function Options() {
  const [activeCategory, setActiveCategory] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    if (activeCategory) {
      const selectedOption = options.find((item) => item.name === activeCategory);
      if (selectedOption) {
        const timer = setTimeout(() => {
          window.scrollTo({ top: 0, behavior: "smooth" });
          navigate(selectedOption.path);
        }, 200);
        // Navigate to the target page after 2 milliseconds

        return () => clearTimeout(timer); // Clear timeout if the component unmounts
      }
    }
  }, [activeCategory, navigate]);

  const toggleCategory = (category) => {
    setActiveCategory(category);
  };
  return (
    <>
      <div className=" w-full flex flex-col justify-start items-center px-[10px] py-[40px] ">
        <div className=" h-[100px] bg-white/40 w-full top-0 left-[40px] 100:pr-[70px] right-[40px] flex fixed justify-between items-center">
          <Link to="/home">
            <div className="w-fit flex justify-center items-center gap-2">
              <div>
                <img className="w-full" src={arrow} alt="back" />
              </div>
              <div className="text-[#fe8734] 100:hidden md:block text-base font-semibold font-openSans">Back</div>
            </div>
          </Link>
          <div className=" 100:w-[60px] iphone:w-[80px] iphone:h-[54.89px]">
            <img className="w-full" src={logo} alt="The Baby Convention" />
          </div>
          <div className="w-[120px] 100:hidden md:block"></div>
        </div>
        <div className=" w-full gap-[72px] flex-col justify-center items-center flex  mt-[130px]">
          <div className=" flex-col  100:w-[90%] justify-center sm:w-[658px] text-center items-center gap-[10px] inline-flex">
            <h3 className="text-[#3c3b3e] 100:text-[40px] md:text-[64px] font-semibold font-playfair">Select Your Registration Category</h3>
            <p className=" text-center text-[#6b6a70] text-lg font-normal font-openSans">Welcome to the Baby Convention! Please Select Your Category to Begin Registration</p>
          </div>
          <div className="flex w-full  justify-center items-center gap-[32px] 100:flex-wrap lg:flex-nowrap ">
            {options.map((item) => (
              <div
                className={`phone:w-[250px] 100:w-full h-[190px] px-2.5 py-2.5 cursor-pointer rounded-[20px] border border-[#e6e6e7] flex-col justify-start items-center gap-2 inline-flex ${
                  activeCategory === item.name ? " bg-[#fff6ed] " : "bg-white"
                }`}
                onClick={() => {
                  toggleCategory(item.name);
                }}
              >
                <div className=" w-full flex justify-end items-end">
                  <input className={`w-5 h-5 rounded-full appearance-none border border-[#e6e6e7] ${activeCategory === item.name ? " bg-[#fe8734]" : "bg-transparent"} `} type="radio" />
                </div>
                <img src={activeCategory === item.name ? item.picture2 : item.picture1} alt="Participant" />
                <div className=" flex-col justify-start items-center gap-1 inline-flex">
                  <h3 className="self-stretch text-center text-[#4f4f4f] text-xl font-semibold font-playfair">{item.title}</h3>
                  <p className="self-stretch text-center text-[#888888] text-xs font-normal font-openSans">{item.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default Options;
